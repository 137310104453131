// import React from 'react'
import React, { useState, useRef } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import PoolCardList from './PoolCardList'
import { usePoolsIDO } from '../../../store/hooks'
import { useWallet } from 'use-wallet'
import './index.less'
import arrowRight from './images/Chevron_Right.png'

const PoolCards = () => {
	const { account } = useWallet()
	const { pools, userDataLoaded } = usePoolsIDO(account)

	const [searchQuery, setSearchQuery] = useState('')
	const handleChangeSearchQuery = (e) => {
		setSearchQuery(e.target.value)
	}
	const tableWrapperEl = useRef(null)
	const scrollToTop = () => {
		tableWrapperEl.current.scrollIntoView({
			behavior: 'smooth',
		})
	}
	return (
		<>
			<div className="main-pool-card" role="table" ref={tableWrapperEl}>
				<Tabs
					defaultActiveKey="finished"
					transition={false}
					// id="noanim-tab-example"
				>
					<Tab className="bsc-nav-tabs" eventKey="live" title="Live">
						<Tabs
							defaultActiveKey="list"
							id="uncontrolled-tab-example"
							className="box-list-grid"
						>
							<Tab eventKey="list">
								<div>
									{pools
										.filter((pool) => pool.isFinished === false)
										.map((pool, index) => (
											<PoolCardList
												key={index}
												pool={pool}
												userDataLoaded={userDataLoaded}
											/>
										))}
								</div>
								
								<br />
							</Tab>
						</Tabs>
					</Tab>
					<Tab className="bsc-nav-tabs" eventKey="finished" title="Finished">
						<>
							<Tabs
								defaultActiveKey="list"
								id="uncontrolled-tab-example"
								className="box-list-grid"
							>
								<Tab eventKey="list">
									<div>
										{pools
											.filter(
												(pool) =>
													pool.isFinished === true 
											)
											.map((pool, index) => (
												<PoolCardList
													key={index}
													pool={pool}
													userDataLoaded={userDataLoaded}
												/>
											))}
									</div>
									<br />
									<div className="back-to-top">
										<button type="button" onClick={scrollToTop}>
											To Top <img src={arrowRight} alt="" />
										</button>
									</div>
									<br />
								</Tab>
							</Tabs>
						</>
					</Tab>
				</Tabs>
			</div>
		</>
	)
}

export default PoolCards
