import { TMenuItem } from './index.d'

import { ReactComponent as HomeIcon } from '../../assets/images/menu/home.svg'
import { ReactComponent as TradeIcon } from '../../assets/images/menu/trade.svg'
import { ReactComponent as NftIcon } from '../../assets/images/menu/nft.svg'
import { ReactComponent as FarmingIcon } from '../../assets/images/menu/farming.svg'
import { ReactComponent as PoolsIDO } from '../../assets/images/menu/idopools.svg'
import { ReactComponent as StakingIcon } from '../../assets/images/menu/staking.svg'
import { ReactComponent as LaunchpadIcon } from '../../assets/images/menu/launchpad.svg'
import { ReactComponent as PredictionIcon } from '../../assets/images/menu/prediction.svg'
import { ReactComponent as MoreIcon } from '../../assets/images/menu/more.svg'

const config: TMenuItem[] = [
  {
    label: 'Home',
    icon: HomeIcon,
    href: ' https://bscs.finance/#/Dashboard',
  },
  {
    label: 'Trade',
    icon: TradeIcon,
    items: [
      {
        label: 'Exchange',
        href: 'https://bscs.finance/#/swap',
      },
      {
        label: 'Liquidity',
        href: 'https://bscs.finance/#/pool',
      },
    ],
  },
  {
    label: 'NFT',
    icon: NftIcon,
    items: [
      {
        label: 'Megamarket',
        href: 'https://bscs.finance/#/nft-megamarket',
      },
      {
        label: 'Genesis Market',
        href: 'https://bscs.finance/#/genesis-market',
      },
      {
        label: 'Stake NFT',
        href: 'https://bscs.finance/#/stakeNFT',
      },
      {
        label: 'My NFT Artworks',
        href: 'https://bscs.finance/#/my-artworks',
      },
    ],
  },
  {
    label: 'INO Launchpad',
    icon: LaunchpadIcon,
    href: 'https://bscs.finance/#/ino-new',
  },
  {
    label: 'IDO Launchpad',
    icon: LaunchpadIcon,
    href: 'https://bscs.finance/#/launchpad',
  },
  {
    label: 'Farms',
    icon: FarmingIcon,
    href: '/farms',
  },
  {
    label: 'Pools',
    icon: StakingIcon,
    items: [
      {
        label: 'Start Pools',
        href: '/',
      },
      {
        label: 'IDO Pools',
        href: '/ido-pools',
      },
      {
        label: 'Launch Pools',
        href: 'https://bscs.finance/#/stakepool',
      },
    ],
  },

  {
    label: 'Prediction',
    icon: PredictionIcon,
    href: 'https://bscs.finance/#/prediction',
  },
  {
    label: 'More',
    icon: MoreIcon,
    items: [
      {
        label: 'Github',
        href: 'https://github.com/BSCStationSwap',
      },
      {
        label: 'Docs',
        href: 'https://docs.bscstation.finance/',
      },
      {
        label: 'Blog',
        href: 'https://bscstation.medium.com',
      },
      {
        label: 'Certik Audit',
        href: 'https://www.certik.org/projects/bscstation',
      },
      {
        label: 'PeckShield Audit',
        href: 'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-BSCStationStartPools-v1.0.pdf',
      },
      {
        label: 'Quillhash Audit',
        href: 'https://github.com/Quillhash/Audit_Reports/blob/master/BSCS%20Smart%20Contract%20Audit%20Report%20-%20QuillAudits.pdf',
      },
    ],
  },
]

export default config
