import BigNumber from 'bignumber.js'
import React, { useEffect, useCallback, useState } from 'react'
import { Clock } from 'react-feather'
import useSousHarvest from '../../../hooks/useSousHarvest'
import useSousHarvestUserLock from '../../../hooks/useSousHarvestUserLock'
import useSousHarvestV3 from '../../../hooks/useSousHarvestV3'
import useSousUnstake from '../../../hooks/useSousUnstake'
import useSousUnstakeUserLock from '../../../hooks/useSousUnstakeUserLock'
import useSousUnstakeV3 from '../../../hooks/useSousUnstakeV3'
import WithdrawModal from './/WithdrawModal'
import WithdrawUserLockModal from './WithdrawUserLockModal'
// import { ScheduleOutlined } from '@ant-design/icons'
// import { Accordion } from 'react-bootstrap';
import { Collapse, Modal, Tooltip } from 'antd'
import { QuestionCircleOutlined, CheckOutlined } from '@ant-design/icons'

import 'antd/dist/antd.css'
import DepositModal from './DepositModal'
import { fetchPoolsUserDataAsync } from '../../../store/pools/index'
import useSousStake from '../../../hooks/useSousStake'
import useSousStakeV3 from '../../../hooks/useSousStakeV3'
import Value from '../../../components/Value'
import { getBalanceNumber } from '../../../utils/formatBalance'
import BlockCountdownUnstake from './BlockCountdownUnstake'
import WalletProviderModal from '../../../components/WalletProviderModal/WalletProviderModal'
import useModal from '../../../hooks/useModal'
import Button from '../../../components/ButtonV2'
import { useDispatch } from 'react-redux'
import { useWallet } from 'use-wallet'
import useSousApprove from '../../../hooks/useSousApprove'
import styled from 'styled-components'
import { ChevronDown, ChevronUp } from 'react-feather'
import { formatNumber } from '../../../utils/formatBalance'
import InfoImages from './images/info.png'
import { BIG_ZERO } from '../../../utils/bigNumber'
import sousChefABI from '../../../config/abi/sousChef.json'
import { getContract } from '../../../utils/contractHelpers'

const PoolCardList = ({ pool, userDataLoaded }) => {
	const [isView, setIsView] = useState(false)
	const { account } = useWallet()
	const dispatch = useDispatch()
	const [requestedApproval, setRequestedApproval] = useState(false)
	const { userData } = pool
	const { onStake } = useSousStake(pool.sousId)
	const { onStakeV3 } = useSousStakeV3(pool.sousId)
	const { onUnstake } = useSousUnstake(pool.sousId)
	const { onUnstakeLock } = useSousUnstakeUserLock(pool.sousId)
	const { onUnstakeV3 } = useSousUnstakeV3(pool.sousId)
	const { onReward } = useSousHarvest(pool.sousId)
	const { onRewardUserLock } = useSousHarvestUserLock(pool.sousId)
	const { onRewardV3 } = useSousHarvestV3(pool.sousId)
	const [pendingTx, setPendingTx] = useState(false)
	const [listSchedule, setListSchedule] = useState([])

	const stakings = userData?.stakedBalance
		? new BigNumber(userData.stakedBalance)
		: BIG_ZERO
	// These will be reassigned later if its Auto CAKE vault
	// let stakingTokenBalance = getBalanceNumber(
	// 	stakings,
	// 	pool.stakingToken.decimals,
	// )
	let stakingTokenDollarBalance = getBalanceNumber(
		stakings.multipliedBy(pool.stakingTokenPrice),
		pool.stakingToken.decimals,
	)

	const earnings = userData?.pendingReward
		? new BigNumber(userData.pendingReward)
		: BIG_ZERO
	let earningTokenDollarBalance = getBalanceNumber(
		earnings.multipliedBy(pool.earningTokenPrice),
		pool.earningToken.decimals,
	)

	const [onPresentWalletProviderModal] = useModal(
		// @ts-ignore
		<WalletProviderModal />,
		'provider',
	)

	const allowance = userData.allowance
	const { onApprove } = useSousApprove(
		pool.stakingToken.address,
		pool.contractAddress,
	)
	const isApproved = account && allowance && allowance.isGreaterThan(0)

	const handleApprove = useCallback(async () => {
		try {
			setRequestedApproval(true)
			await onApprove()
			dispatch(fetchPoolsUserDataAsync(account))
			setRequestedApproval(false)
		} catch (e) {
			setRequestedApproval(false)
		}
	}, [onApprove, dispatch, account, pool])

	const handleStake = async (amount) => {
		await onStake(amount, pool.stakingToken.decimals)
		dispatch(fetchPoolsUserDataAsync(account))
	}
	const handleStakeV3 = async (amount) => {
		await onStakeV3(amount, pool.stakingToken.decimals)
		dispatch(fetchPoolsUserDataAsync(account))
	}

	const handleUnstake = async (amount) => {
		await onUnstake(amount, pool.stakingToken.decimals)
		dispatch(fetchPoolsUserDataAsync(account))
	}

	const handleUnstakeUserLock = async (amount) => {
		await onUnstakeLock(amount, pool.stakingToken.decimals)
		dispatch(fetchPoolsUserDataAsync(account))
	}

	const handleUnstakeV3 = async (amount) => {
		await onUnstakeV3(amount, pool.stakingToken.decimals)
		dispatch(fetchPoolsUserDataAsync(account))
	}

	const handleReward = useCallback(async () => {
		try {
			setPendingTx(true)
			await onReward()
			dispatch(fetchPoolsUserDataAsync(account))
			setPendingTx(false)
		} catch (e) {
			setPendingTx(false)
			console.error('err handleReward', e)
		}
	}, [account, dispatch, onReward, pool])

	const handleRewardUserLock = useCallback(async () => {
		try {
			setPendingTx(true)
			await onRewardUserLock()
			dispatch(fetchPoolsUserDataAsync(account))
			setPendingTx(false)
		} catch (e) {
			setPendingTx(false)
			console.error('err handleRewardUserLock', e)
		}
	}, [account, dispatch, onRewardUserLock, pool])

	const handleRewardV3 = useCallback(async () => {
		try {
			setPendingTx(true)
			await onRewardV3()
			dispatch(fetchPoolsUserDataAsync(account))
			setPendingTx(false)
		} catch (e) {
			setPendingTx(false)
			console.error('err handleRewardV3', e)
		}
	}, [account, dispatch, onRewardV3, pool])

	const [onPresentStakeInPoolModal] = useModal(
		<DepositModal
			stakingToken={pool.stakingToken}
			max={userData.stakingTokenBalance}
			onConfirm={handleStake}
		/>,
	)
	const [onPresentStakeInPoolModalV3] = useModal(
		<DepositModal
			stakingToken={pool.stakingToken}
			max={userData.stakingTokenBalance}
			onConfirm={handleStakeV3}
		/>,
	)

	const [onPresentWithdrawModal] = useModal(
		<WithdrawModal
			fees={pool.fees}
			lastStakingBlock={userData.lastStakingBlock}
			blockPeriod={pool.blockPeriod}
			stakingToken={pool.stakingToken}
			max={userData.stakedBalance}
			onConfirm={handleUnstake}
		/>,
	)
	const [onPresentWithdrawUserLockModal] = useModal(
		<WithdrawUserLockModal
			fees={pool.fees}
			lastStakingBlock={userData.lastStakingBlock}
			blockPeriod={pool.blockPeriod}
			stakingToken={pool.stakingToken}
			max={userData.unstakeAmount}
			balance={userData.stakedBalance}
			onConfirm={handleUnstakeUserLock}
		/>,
	)
	const [onPresentWithdrawUserLockModalV3] = useModal(
		<WithdrawUserLockModal
			fees={pool.fees}
			lastStakingBlock={userData.lastStakingBlock}
			blockPeriod={pool.blockPeriod}
			stakingToken={pool.stakingToken}
			max={userData.unstakeAmount}
			balance={userData.stakedBalance}
			onConfirm={handleUnstakeV3}
		/>,
	)

	const { Panel } = Collapse

	function callback(key) {
		console.log(key)
	}
	const [isModalVisible, setIsModalVisible] = useState(false)

	const showModal = () => {
		setIsModalVisible(true)
	}

	const handleOk = () => {
		setIsModalVisible(false)
	}

	const handleCancel = () => {
		setIsModalVisible(false)
	}
	const [isCollapseVisible, setIsCollapseVisible] = useState(true)
	const textTooltip = (
		<div>
			<div className="text-tooltip">
				<span className="icon">
					<CheckOutlined />
				</span>
				<span className="text">Calculated based on current rates.</span>
			</div>
			<div className="text-tooltip">
				<span className="icon">
					<CheckOutlined />
				</span>
				<span className="text">
					All APR are estimation and by it does not represent guaranteed
					returns.
				</span>
			</div>
		</div>
	)
	const contractAddressPool = getContract(sousChefABI, pool.contractAddress)
	useEffect(() => {
		if (account && contractAddressPool) {
			contractAddressPool.methods
				.getUserStakedCount(account)
				.call()
				.then(async (resStakedCount) => {
					if (resStakedCount !== '0') {
						let arrData = []
						// get list api
						for (let i = 0; i < parseInt(resStakedCount); i++) {
							arrData.push(
								contractAddressPool.methods.stakeDetails(account, i).call(),
							)
						}
						let listData = []
						// get result
						await Promise.all([arrData]).then((resAll) => {
							for (let i = 0; i < parseInt(resStakedCount); i++) {
								resAll[0][i].then((data) => {
									listData.push(data)
								})
							}
						})
						setTimeout(() => {
							setListSchedule(listData)
						}, 1000)
					}
				})
		}
	}, [account])
	const contentModalClick = (
		<>
			<table className="table-modal">
				<tr>
					<th>Amount</th>
					<th>Stake block</th>
					<th>Remaining unlock</th>
				</tr>
				{listSchedule
					.filter((x) => x.amount > 0)
					.map((item, i) => {
						return (
							<tr key={`schedule_${i}`}>
								<td>{item.amount / 1e18}</td>
								<td>{item.startStakeBlock}</td>
								<td>
									{item.endStakeBlock}{' '}
									<a
										href={`https://bscscan.com/block/countdown/${item.endStakeBlock}`}
										target="_blank"
										rel="noreferrer"
										className="text-white"
									>
										<Clock className="ml-1" size={16} />
									</a>
								</td>
							</tr>
						)
					})}
			</table>
		</>
	)
	const contentHeader = (
		<div className="box-header-list">
			<div className="item-tow-part d-flex w-3-12 ido-pool">
				{!pool.idoLP ? (
					<>
						{pool.birthday ? (
							<div className="box-logo">
								<img
									src={`/tokens/birthday-${pool.earningToken.symbol.toLowerCase()}.png?v=5`}
									alt=""
									className="logo-big"
								/>
								<img
									src={`/tokens/birthday-${pool.stakingToken.symbol.toLowerCase()}.png?v=5`}
									alt=""
									className="logo-small"
								/>
							</div>
						) : (
							<div className="box-logo">
								<img
									src={`/tokens/${pool.earningToken.symbol.toLowerCase()}.png?v=5`}
									alt=""
									className="logo-big"
								/>
								<img
									src={`/tokens/${pool.stakingToken.symbol.toLowerCase()}.png?v=5`}
									alt=""
									className="logo-small"
								/>
							</div>
						)}
					</>
				) : (
					<div className="box-logo lp">
						<img
							src={`/tokens/${pool.earningToken.symbol.toLowerCase()}.png?v=5`}
							alt=""
							className="logo-big lp"
						/>
						<img
							src={`/tokens/${pool.stakingToken.symbol.toLowerCase()}.png?v=5`}
							alt=""
							className="logo-small lp"
						/>
					</div>
				)}
			</div>
			<div className="item-tow-part d-flex w-3-12 ido-pool">
				<div className="box-text-logo">
					<div className="token-name d-flex-ido">
						<p className="text-big">
							{pool.stakingToken.symbol} - {pool.earningToken.symbol}
						</p>
					</div>
					<div className="fixible-earn">
						<p className="text-small">{pool.lockup / 30} Months</p>
					</div>
				</div>
			</div>
			<div className="item-tow-part ido-pool dis-none-max500">
				<div className="box-earned">
					<p className="text-earned">{pool.earningToken.symbol} Earned</p>
					<p className="total-coin">
						<Value
							size={20}
							value={
								account
									? getBalanceNumber(
											userData.pendingReward,
											pool.earningToken.decimals,
									  )
									: 0
							}
						/>
					</p>
				</div>
			</div>
			<div className="item-tow-part d-flex ido-pool">
				<div className="box-apr cus">
				<p className="text-apr icon">
							APR 
						</p>
					<p className="number-apr">
						{pool.apr ? <>{formatNumber(pool.apr)}%</> : '0%'}
					</p>
				</div>
			</div>

			<div className="item-tow-part dis-none dis-none-max990 ido-pool">
				<div className="box-total-staked">
					<p className="text-total-staked">Lock-up term</p>
					<p className="number-total-staked d-flex">{pool.lockup} days</p>
				</div>
			</div>
			<div className="item-tow-part dis-none dis-none-max1200 ido-pool">
				<div className="box-total-staked">
					<p className="number-total-staked d-flex">
						{/* <BlockCountdownUnstake pool={pool} /> */}
						{/* <button type="button" className="btn-schedule" onClick={showModal}>
							<ScheduleOutlined />
						</button> */}
					</p>
				</div>
			</div>

			<div className="item-tow-part dis-none w-1-12 ido-pool">
				<Button
					link
					click={() => setIsView(!isView)}
					className="height-80"
					text={isView ? 'Hide' : 'Details'}
					right={
						isView ? (
							<ChevronUp color="#0DBA88" size={16} />
						) : (
							<ChevronDown color="#0DBA88" size={16} />
						)
					}
				/>
			</div>
			<Modal
				className="modal-schedule"
				title="Schedule"
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
			>
				{contentModalClick}
			</Modal>
		</div>
	)
	const contentBody = (
		<div className="box-content-body">
			<div className="item-three-part">
				<p className="text-ends-in">
					<BlockCountdownUnstake pool={pool} />
				</p>
				<p className="text-view-info">
					<a
						href={`${pool.stakingToken.projectLink}`}
						target="_blank"
						rel="noreferrer"
					>
						View project <img src={InfoImages} alt="" />
					</a>
				</p>
				{pool.idoLP ? (
					<>
						<p className="text-view-info">
							<a
								href={`https://bscscan.com/address/${pool.stakingToken.address.toLowerCase()}`}
								target="_blank"
								rel="noreferrer"
							>
								View Contract LP
								<img src={InfoImages} alt="" />
							</a>
						</p>
						<p className="text-view-info">
							<a
								href={`https://bscscan.com/address/${pool.contractAddress.toLowerCase()}`}
								target="_blank"
								rel="noreferrer"
							>
								View contract pool <img src={InfoImages} alt="" />
							</a>
						</p>
					</>
				) : (
					<>
						<p className="text-view-info">
							<a
								href={`https://bscscan.com/address/${pool.contractAddress.toLowerCase()}`}
								target="_blank"
								rel="noreferrer"
							>
								View contract pool
								<img src={InfoImages} alt="" />
							</a>
						</p>
					</>
				)}
			</div>
			<div className="item-four-part">
				<div className="box-harvest">
					<div className="harvest-left">
						<p className="text-harvest">{pool.earningToken.symbol} Earned</p>
						<p className="total-coin">
							<span className="row-flex">
								<Value
									size={18}
									value={
										account
											? getBalanceNumber(
													userData.pendingReward,
													pool.earningToken.decimals,
											  )
											: 0
									}
								/>
								<span className="mgl-5 card-details">
									<span className="mgl-5 token-earned">
										{pool.earningToken.symbol}
									</span>
								</span>
							</span>
						</p>
						<p className="total-usd">
							<Value size={15} unit={'$'} value={earningTokenDollarBalance} />
						</p>
					</div>
					<div className="harvest-right">
						<Button
							primary
							disabled={
								!account ||
								pendingTx ||
								userData.pendingReward.eq(new BigNumber(0)) ||
								!isApproved ||
								pool.disHarvest === true
							}
							loading={pendingTx}
							text="Harvest"
							click={
								pool.userLock
									? pool.versionSC === 3
										? handleRewardV3
										: handleRewardUserLock
									: handleReward
							}
						/>
					</div>
				</div>
			</div>
			<div className="item-four-part">
				<div className="box-start-staking">
					<p className="text-start-staking">Start staking</p>
					<Value
						size={20}
						value={
							account
								? getBalanceNumber(
										userData.stakedBalance,
										pool.stakingToken.decimals,
								  )
								: 0
						}
					/>
					{!pool.idoLP && (
						<p className="total-usd">
							<Value size={15} unit={'$'} value={stakingTokenDollarBalance} />
						</p>
					)}

					<div className="d-flex mar-t-5">
						<Button
							className="mr-2"
							ghost
							disabled={
								!account ||
								userData.stakedBalance.eq(new BigNumber(0)) ||
								!isApproved ||
								pool.disUnstake ||
								userData.unstakeAmount <= 0
							}
							text="Unstake"
							click={
								pool.userLock
									? pool.versionSC === 3
										? onPresentWithdrawUserLockModalV3
										: onPresentWithdrawUserLockModal
									: onPresentWithdrawModal
							}
						/>

						{pool.versionSC == 3 ? (
							<Button
								disabled={
									!account ||
									!isApproved ||
									pool.isFinished === true ||
									pool.disStake === true
								}
								text="+"
								click={onPresentStakeInPoolModalV3}
								primary
							/>
						) : (
							<Button
								disabled={
									!account ||
									!isApproved ||
									pool.isFinished === true ||
									pool.disStake === true
								}
								text="+"
								click={onPresentStakeInPoolModal}
								primary
							/>
						)}
					</div>
					{account ? (
						<>
							{userDataLoaded ? (
								<>
									{!isApproved && (
										<Button
											loading={requestedApproval}
											disabled={requestedApproval}
											className="w-full mar-t-10"
											text="Approve Contract"
											click={handleApprove}
											primary
										/>
									)}
								</>
							) : (
								<Button className="w-full Loading" text="Loading..." />
							)}
						</>
					) : (
						<Button
							className="w-full mar-t-10"
							text="Connect Wallet"
							click={onPresentWalletProviderModal}
							primary
						/>
					)}
				</div>
			</div>
		</div>
	)
	return (
		<>
			{pool.isNew ? (
				<div className="box-list-item ido-pool">
					<Collapse visible={isCollapseVisible} onChange={callback}>
						<Panel header={contentHeader} key="1">
							{contentBody}
						</Panel>
					</Collapse>
				</div>
			) : (
				<div className="box-list-item">
					<Collapse visible={isCollapseVisible} onChange={callback}>
						<Panel header={contentHeader} key="1">
							{contentBody}
						</Panel>
					</Collapse>
				</div>
			)}
		</>
	)
}
export default PoolCardList
