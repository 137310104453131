import tokens from "./tokens";

const poolsConfig = [
  // typePool = 1 --> start pool
  // typePool = 2 --> farm pool
  // typePool = 3 --> IDO pool
  // countdownt= true ---> countdown
  // isDay = true --> day
  // isDay = false --> month

  // typeLP = 1 --> BUSD LP
  // typeLP = 2 --> BNB LP
  // typeLP = 3 --> link LP

  // {
  // 	sousId: 14,
  // 	stakingToken: tokens.bscs,
  // 	earningToken: tokens.bscs,
  // 	contractAddress: '0x06Ce5d3f04856a514D51d6328A4E2378aa0739a6',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 10168238,
  // 	endBlock: 11861005,
  // 	tokenPerBlock: 340000000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: true,
  // 	disStake: true,
  // 	disHarvest: true,
  // },

  // lock temp
  {
    sousId: 85,
    stakingToken: tokens.bscs,
    earningToken: tokens.bscs,
    contractAddress: "0x6d7C8aD9aBBFEE50bB3be2B58D4E08a755576F80",
    fees: 2,
    blockPeriod: 86400,
    startBlock: 13078242,
    endBlock: 16422758,
    unstakeBlock: 13133984,
    tokenPerBlock: 289351900000000000,
    isFinished: true,
    stakingLimit: 0,
    // 	disUnstake: false,
    // 	disStake: false,
    // 	disHarvest: false,
    typePool: 1,
    chain: 0,
  },
  {
    sousId: 14,
    stakingToken: tokens.bscs,
    earningToken: tokens.bscs,
    contractAddress: "0x5a42e0B986Af9756d47C263e9b4A54e3958C19dc",
    fees: 0,
    blockPeriod: 86400,
    startBlock: 10474607,
    endBlock: 13068000,
    tokenPerBlock: 771000000000000000,
    isFinished: true,
    stakingLimit: 0,
    // 	disUnstake: false,
    // 	disStake: false,
    // 	disHarvest: false,
    typePool: 1,
    chain: 0,
  },
  // {
  // 	sousId: 0,
  // 	stakingToken: tokens.dpet,
  // 	earningToken: tokens.bscs,
  // 	contractAddress: '0x587D14BB7BdC34aA1061c4E6F7F495d6af8faD36',
  // 	fees: 0.1,
  // 	blockPeriod: 86400,
  // 	startBlock: 9489500,
  // 	endBlock: 12945380,
  // 	tokenPerBlock: 144670000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  {
    sousId: 1,
    stakingToken: tokens.bscs,
    earningToken: tokens.dpet,
    contractAddress: "0xc2E393920B8940008f40bb444eB2803Ec15D6F3C",
    fees: 0.1,
    blockPeriod: 86400,
    startBlock: 9489380,
    endBlock: 12945380,
    tokenPerBlock: 1446700000000000,
    isFinished: true,
    stakingLimit: 0,
    // 	disUnstake: false,
    // 	disStake: false,
    // 	disHarvest: false,
    typePool: 1,
    chain: 0,
  },
  // {
  //   sousId: 2,
  //   stakingToken: tokens.revomon,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0x934ADC046b057316dB855F6133513F4967AC421F",
  //   fees: 0.1,
  //   blockPeriod: 86400,
  //   startBlock: 9550960,
  //   endBlock: 13006960,
  //   tokenPerBlock: 86800000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   // 	disUnstake: false,
  //   // 	disStake: false,
  //   // 	disHarvest: false,
  //   typePool: 1,
  //   chain: 0,
  // },
  // {
  //   sousId: 3,
  //   stakingToken: tokens.bscs,
  //   earningToken: tokens.revomon,
  //   contractAddress: "0x27Aecb73aC320780e6fcFA7f815605763e84A05e",
  //   fees: 0.1,
  //   blockPeriod: 86400,
  //   startBlock: 9550960,
  //   endBlock: 13006960,
  //   tokenPerBlock: 43200000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   // 	disUnstake: false,
  //   // 	disStake: false,
  //   // 	disHarvest: false,
  //   typePool: 1,
  //   chain: 0,
  // },
  // {
  // 	sousId: 4,
  // 	stakingToken: tokens.fara,
  // 	earningToken: tokens.bscs,
  // 	contractAddress: '0x4515d96D96c21E283c63c418C226e41C2FBcb530',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 9715894,
  // 	endBlock: 12000987,
  // 	tokenPerBlock: 77100000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  // {
  // 	sousId: 5,
  // 	stakingToken: tokens.bscs,
  // 	earningToken: tokens.polycrowns,
  // 	contractAddress: '0xEEE06D3eb7b9176b8930EE9F9391966704D411be',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 9688995,
  // 	endBlock: 11390335,
  // 	tokenPerBlock: 5780000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: true,
  // 	disStake: true,
  // 	disHarvest: true,
  // 	typePool: 1,
  // },
  // {
  // 	sousId: 6,
  // 	stakingToken: tokens.polycrowns,
  // 	earningToken: tokens.bscs,
  // 	contractAddress: '0xb74A4F89226bA97Bca1eAEe102F4A94eB76BA983',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 9688995,
  // 	endBlock: 11390335,
  // 	tokenPerBlock: 289000000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },

  // {
  // 	sousId: 7,
  // 	stakingToken: tokens.bscs,
  // 	earningToken: tokens.bnu,
  // 	contractAddress: '0xa00459864F9688f07172Fad97A2ED78074a3e711',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 9662289,
  // 	endBlock: 11361489,
  // 	tokenPerBlock: 115700000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  // {
  // 	sousId: 8,
  // 	stakingToken: tokens.bnu,
  // 	earningToken: tokens.bscs,
  // 	contractAddress: '0x2d40b60751aC466e19B5dAFeDD02bD6878254c6E',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 9662289,
  // 	endBlock: 11361489,
  // 	tokenPerBlock: 57800000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  // {
  // 	sousId: 9,
  // 	stakingToken: tokens.bscs,
  // 	earningToken: tokens.kingdomgame,
  // 	contractAddress: '0x015Db4F5363E3F63b6BD3Ca213d9a995F0B0d21D',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 9911620,
  // 	endBlock: 13051620,
  // 	tokenPerBlock: 2025000000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  // {
  // 	sousId: 10,
  // 	stakingToken: tokens.kingdomgame,
  // 	earningToken: tokens.bscs,
  // 	contractAddress: '0x2666D09671F3A80eD10C8C0FfF79b94A03664c58',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 9911620,
  // 	endBlock: 13051620,
  // 	tokenPerBlock: 86800000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  // {
  // 	sousId: 11,
  // 	stakingToken: tokens.bscs,
  // 	earningToken: tokens.polkaplay,
  // 	contractAddress: '0xA666de6cFB0023e1078A896b511A5131fd08ab2D',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 9940668,
  // 	endBlock: 11236668,
  // 	tokenPerBlock: 770000000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  // {
  // 	sousId: 12,
  // 	stakingToken: tokens.polkaplay,
  // 	earningToken: tokens.bscs,
  // 	contractAddress: '0x6B397b5Dc1D50883E4Cf9078Ba4fA03Aa26Ad970',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 9940668,
  // 	endBlock: 11668668,
  // 	tokenPerBlock: 57800000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  // {
  // 	sousId: 13,
  // 	stakingToken: tokens.bscs,
  // 	earningToken: tokens.tfi,
  // 	contractAddress: '0x0Ea7d248AE43AA4fb17C8E8464081317d43Cd6AE',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 10196755,
  // 	endBlock: 11060055,
  // 	tokenPerBlock: 289000000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },

  // pool stake new
  // pool stake new
  // pool stake new

  {
    sousId: 110,
    stakingToken: tokens.bscs,
    earningToken: tokens.bscs,
    contractAddress: "0x72a643471020fce60248d37548C459b87CAB18c9",
    fees: 0,
    blockPeriod: 86400,
    startBlock: 16568414,
    endBlock: 20024414,
    tokenPerBlock: 231480000000000000,
    isFinished: true,
    stakingLimit: 0,
    typePool: 1,
    lockup: 7,
    userLock: true,
    versionSC: 3,
    chain: 0,
  },
  {
    sousId: 116,
    stakingToken: tokens.bscs,
    earningToken: tokens.istep,
    contractAddress: "0x6823c523dB20c60B75e25dab72446998ACD8cEf9",
    fees: 0,
    blockPeriod: 86400,
    startBlock: 18253948,
    endBlock: 19549948,
    tokenPerBlock: 260000000000000000,
    isFinished: true,
    stakingLimit: 0,
    typePool: 1,
    lockup: 7,
    userLock: true,
    versionSC: 3,
    chain: 0,
  },
  // {
  //   sousId: 117,
  //   stakingToken: tokens.istep,
  //   earningToken: tokens.istep,
  //   contractAddress: "0x385716713B1d2E37f9130aA2c70A7f1f63e5E252",
  //   fees: 0,
  //   blockPeriod: 86400,
  //   startBlock: 18253948,
  //   endBlock: 19549948,
  //   tokenPerBlock: 520833300000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   typePool: 1,
  //   lockup: 7,
  //   userLock: true,
  //   versionSC: 3,
  //   chain: 0,
  // },
  {
    sousId: 114,
    stakingToken: tokens.bscs,
    earningToken: tokens.mst,
    contractAddress: "0x0fdAe9E06E545ceF9235Ce1D042de7E04AFA6741",
    fees: 0,
    blockPeriod: 86400,
    startBlock: 16739466,
    endBlock: 20195466,
    tokenPerBlock: 28935000000000000,
    isFinished: true,
    stakingLimit: 0,
    typePool: 1,
    lockup: 7,
    userLock: true,
    versionSC: 3,
    chain: 0,
  },
  {
    sousId: 112,
    stakingToken: tokens.mst,
    earningToken: tokens.bscs,
    contractAddress: "0x2a6848df4DdbD12Aa5ABaff30EC626eC1134fa8E",
    fees: 0,
    blockPeriod: 86400,
    startBlock: 16739466,
    endBlock: 20195466,
    tokenPerBlock: 57870000000000000,
    isFinished: true,
    stakingLimit: 0,
    typePool: 1,
    lockup: 7,
    userLock: true,
    versionSC: 3,
    chain: 0,
  },
  {
    sousId: 107,
    stakingToken: tokens.zwz,
    earningToken: tokens.zwz,
    contractAddress: "0xf50686108d960e90F6e67DE2E74edE622ab11c83",
    fees: 2,
    blockPeriod: 86400,
    startBlock: 15364743,
    endBlock: 18820743,
    tokenPerBlock: 145000000000000000,
    isFinished: true,
    stakingLimit: 0,
    typePool: 1,
    lockup: 15,
    userLock: true,
    versionSC: 3,
    chain: 0,
  },
  {
    sousId: 106,
    stakingToken: tokens.zwz,
    earningToken: tokens.bscs,
    contractAddress: "0xC015A9684Ada72140AB83AA8c0a12Ec4CF3B4F6d",
    fees: 2,
    blockPeriod: 86400,
    startBlock: 15364743,
    endBlock: 18820743,
    tokenPerBlock: 43000000000000000,
    isFinished: true,
    stakingLimit: 0,
    typePool: 1,
    lockup: 15,
    userLock: true,
    versionSC: 3,
    chain: 0,
  },
  {
    sousId: 105,
    stakingToken: tokens.bscs,
    earningToken: tokens.zwz,
    contractAddress: "0xAF2fE303f104171A48d9Ab7845264B76Ee6aB11c",
    fees: 2,
    blockPeriod: 86400,
    startBlock: 15364743,
    endBlock: 18820743,
    tokenPerBlock: 43000000000000000,
    isFinished: true,
    stakingLimit: 0,
    typePool: 1,
    lockup: 15,
    userLock: true,
    versionSC: 3,
    chain: 0,
  },

  {
    sousId: 102,
    stakingToken: tokens.bscs,
    earningToken: tokens.ack,
    contractAddress: "0x41d6cbE0bf70a338928e6CC3EfA4AAc615C2A600",
    fees: 2,
    blockPeriod: 86400,
    startBlock: 14817805,
    endBlock: 16545805,
    tokenPerBlock: 2604166600000000,
    isFinished: true,
    stakingLimit: 0,
    typePool: 1,
    chain: 0,
  },

  {
    sousId: 103,
    stakingToken: tokens.ack,
    earningToken: tokens.ack,
    contractAddress: "0x9773F18619fD7D2DFc31A7c5f19caaf20BDa2ba4",
    fees: 2,
    blockPeriod: 86400,
    startBlock: 14817805,
    endBlock: 18273805,
    tokenPerBlock: 34722222000000000,
    isFinished: true,
    stakingLimit: 0,
    typePool: 1,
    lockup: 30,
    userLock: true,
    versionSC: 3,
    chain: 0,
  },
  {
    sousId: 100,
    stakingToken: tokens.bscs,
    earningToken: tokens.dal,
    contractAddress: "0xEe73bEC4bfCb644E12410f4F5E2BF051E128751A",
    fees: 2,
    blockPeriod: 86400,
    startBlock: 14045129,
    endBlock: 15773129,
    tokenPerBlock: 1446759259000000,
    isFinished: true,
    stakingLimit: 0,
    typePool: 1,
    chain: 0,
  },

  {
    sousId: 99,
    stakingToken: tokens.dal,
    earningToken: tokens.dal,
    contractAddress: "0xb05d8707142fF3e52bA2F610D603F22b638116Ec",
    fees: 2,
    blockPeriod: 86400,
    startBlock: 14045129,
    endBlock: 50000648,
    tokenPerBlock: 2893500000000000,
    isFinished: true,
    stakingLimit: 0,
    typePool: 1,
    lockup: 15,
    userLock: true,
    versionSC: 3,
    chain: 0,
  },

  {
    sousId: 95,
    stakingToken: tokens.next,
    earningToken: tokens.next,
    contractAddress: "0xf8829611f27001aEaF9FC1fce462EE2d5f7899ff",
    fees: 2,
    blockPeriod: 86400,
    startBlock: 13298446,
    endBlock: 13946446,
    unstakeBlock: 13622446,
    tokenPerBlock: 231481500000000000,
    isFinished: true,
    stakingLimit: 0,
    // 	disUnstake: false,
    // 	disStake: false,
    // 	disHarvest: false,
    typePool: 1,
    lockup: 15,
    chain: 0,
  },
  // {
  // 	sousId: 92,
  // 	stakingToken: tokens.bscs,
  // 	earningToken: tokens.btl,
  // 	contractAddress: '0x29725C36073B0b36Fe4cD4709fcC8260526CF9b7',
  // 	fees: 0,
  // 	blockPeriod: 86400,
  // 	startBlock: 13274668,
  // 	endBlock: 14930606,
  // 	tokenPerBlock: 144675900000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: true,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  // {
  // 	sousId: 91,
  // 	stakingToken: tokens.dnft,
  // 	earningToken: tokens.dnft,
  // 	contractAddress: '0xfC96255720a1F1ca78a2aCB2F23843b64EA4A8C0',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 13246712,
  // 	endBlock: 14082841,
  // 	tokenPerBlock: 4000000000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  // {
  // 	sousId: 90,
  // 	stakingToken: tokens.dnft,
  // 	earningToken: tokens.dnft,
  // 	contractAddress: '0x52B2F394D3c51C0b823739d67C2503eE07D6aa6c',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 13274648,
  // 	endBlock: 14110777,
  // 	tokenPerBlock: 63000000000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	// 	disUnstake: false,
  // 	// 	disStake: false,
  // 	// 	disHarvest: false,
  // 	typePool: 1,
  // 	lockup: 14,
  // 	userLock: true,
  // },
  // {
  // 	sousId: 88,
  // 	stakingToken: tokens.bscs,
  // 	earningToken: tokens.ack,
  // 	contractAddress: '0x6D33dB099297341F467c66Dff7bB272873c4E95e',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 13131077,
  // 	endBlock: 14800335,
  // 	tokenPerBlock: 5208300000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  {
    sousId: 87,
    stakingToken: tokens.ack,
    earningToken: tokens.ack,
    contractAddress: "0x17B3b8e475791a1c6f6929782950D4216f796472",
    fees: 2,
    blockPeriod: 86400,
    startBlock: 13131077,
    endBlock: 14800335,
    unstakeBlock: 13957206,
    tokenPerBlock: 34722200000000000,
    isFinished: true,
    stakingLimit: 0,
    // 	disUnstake: false,
    // 	disStake: false,
    // 	disHarvest: false,
    typePool: 1,
    lockup: 30,
    chain: 0,
  },

  // {
  // 	sousId: 86,
  // 	stakingToken: tokens.bscs,
  // 	earningToken: tokens.real,
  // 	contractAddress: '0xb3B8e3f6946dE7F4CA136618c4D42B38C8855DAA',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 13105928,
  // 	endBlock: 14601643,
  // 	tokenPerBlock: 193000000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  // {
  // 	sousId: 84,
  // 	stakingToken: tokens.bscs,
  // 	earningToken: tokens.real,
  // 	contractAddress: '0x1427d8Be39478876d91629c59598472751EFb7aa',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 12987301,
  // 	endBlock: 15422688,
  // 	tokenPerBlock: 193000000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  // {
  // 	sousId: 83,
  // 	stakingToken: tokens.real,
  // 	earningToken: tokens.real,
  // 	contractAddress: '0xE147A18Fe1bc95337DC974D326A17e5eD3e3b8df',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 12987301,
  // 	unstakeBlock: 13367495,
  // 	endBlock: 15422688,
  // 	tokenPerBlock: 579000000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	lockup: 14,
  // 	typePool: 1,
  // },
  {
    sousId: 76,
    stakingToken: tokens.bscs,
    earningToken: tokens.hwl,
    contractAddress: "0x881561CFc1d3EFE0F0aB10F3693FDd4B8156caeA",
    fees: 2,
    blockPeriod: 86400,
    startBlock: 12827500,
    endBlock: 15400500,
    tokenPerBlock: 231481500000000000,
    isFinished: true,
    stakingLimit: 0,
    // 	disUnstake: false,
    // 	disStake: false,
    // 	disHarvest: false,
    typePool: 1,
    chain: 0,
  },
  // {
  // 	sousId: 75,
  // 	stakingToken: tokens.hwl,
  // 	earningToken: tokens.bscs,
  // 	contractAddress: '0xF33861905155d3229A06DCeE351baA6e04e47D6E',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 12827500,
  // 	endBlock: 15400500,
  // 	tokenPerBlock: 30864200000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  // {
  //   sousId: 74,
  //   stakingToken: tokens.hwl,
  //   earningToken: tokens.hwl,
  //   contractAddress: "0xd5C60D1c2C63750C5Dd748392906426439593F16",
  //   fees: 2,
  //   blockPeriod: 86400,
  //   startBlock: 12827500,
  //   endBlock: 15400500,
  //   tokenPerBlock: 578703700000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   // 	disUnstake: false,
  //   // 	disStake: false,
  //   // 	disHarvest: false,
  //   typePool: 1,
  //   chain: 0,
  // },
  // {
  // 	sousId: 72,
  // 	stakingToken: tokens.bscs,
  // 	earningToken: tokens.polkaplay,
  // 	contractAddress: '0x71Cb6Ba3b6019d32e8acad095dc07Be99A16a7fF',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 12780280,
  // 	endBlock: 13606409,
  // 	tokenPerBlock: 694444400000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  {
    sousId: 73,
    stakingToken: tokens.polkaplay,
    earningToken: tokens.bscs,
    contractAddress: "0xf848F551B4728bb8338835C58299D774477cecF8",
    fees: 2,
    blockPeriod: 86400,
    startBlock: 12780280,
    endBlock: 13606409,
    tokenPerBlock: 17361100000000000,
    isFinished: true,
    stakingLimit: 0,
    // 	disUnstake: false,
    // 	disStake: false,
    // 	disHarvest: false,
    typePool: 1,
    chain: 0,
  },
  {
    sousId: 71,
    stakingToken: tokens.cogi,
    earningToken: tokens.cogi,
    contractAddress: "0xa3d0407de3da12593537e0e685E3b8bE2648b092",
    fees: 2,
    blockPeriod: 86400,
    startBlock: 12711686,
    endBlock: 15210073,
    tokenPerBlock: 96000000000000000,
    isFinished: true,
    stakingLimit: 0,
    // 	disUnstake: false,
    // 	disStake: false,
    // 	disHarvest: false,
    typePool: 1,
    chain: 0,
  },
  // {
  //   sousId: 69,
  //   stakingToken: tokens.cogi,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0x95F2DF23Ea5B35314938D70c8bAA8a0F1191212b",
  //   fees: 2,
  //   blockPeriod: 86400,
  //   startBlock: 12699637,
  //   endBlock: 15200024,
  //   tokenPerBlock: 38580200000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   // 	disUnstake: false,
  //   // 	disStake: false,
  //   // 	disHarvest: false,
  //   typePool: 1,
  //   chain: 0,
  // },
  // {
  // 	sousId: 70,
  // 	stakingToken: tokens.bscs,
  // 	earningToken: tokens.cogi,
  // 	contractAddress: '0x522977e4Fe441f8E44Bbd10F26F73C291aaed823',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 12699637,
  // 	endBlock: 15200024,
  // 	tokenPerBlock: 57870400000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	// 	disUnstake: false,
  // 	// 	disStake: false,
  // 	// 	disHarvest: false,
  // 	typePool: 1,
  // },

  // {
  //   sousId: 64,
  //   stakingToken: tokens.oly,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0xA4Cac7BD6F7cEa85a20943eB20B690dc807eD421",
  //   fees: 2,
  //   blockPeriod: 86400,
  //   startBlock: 12467320,
  //   endBlock: 15055320,
  //   tokenPerBlock: 77160000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   // 	disUnstake: false,
  //   // 	disStake: false,
  //   // 	disHarvest: false,
  //   typePool: 1,
  //   chain: 0,
  // },
  // {
  // 	sousId: 65,
  // 	stakingToken: tokens.bscs,
  // 	earningToken: tokens.oly,
  // 	contractAddress: '0xC8eEeDa5b6F758208abA89bb3d614Ea135c4f29f',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 12467320,
  // 	endBlock: 15055320,
  // 	tokenPerBlock: 77160000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  {
    sousId: 66,
    stakingToken: tokens.oly,
    earningToken: tokens.oly,
    contractAddress: "0x2f66931d8aa91058B1FCC4Fa38F408dF7dbC56ED",
    fees: 2,
    blockPeriod: 86400,
    startBlock: 12467320,
    endBlock: 15055320,
    tokenPerBlock: 115740000000000000,
    isFinished: true,
    stakingLimit: 0,
    // 	disUnstake: false,
    // 	disStake: false,
    // 	disHarvest: false,
    typePool: 1,
    chain: 0,
  },
  // {
  // 	sousId: 63,
  // 	stakingToken: tokens.bscs,
  // 	earningToken: tokens.lfw,
  // 	contractAddress: '0x97c68E1DD1d344926fc2Ef409b0848BFea7C8e8B',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 12418500,
  // 	endBlock: 13279500,
  // 	tokenPerBlock: 23148100000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  // {
  // 	sousId: 59,
  // 	stakingToken: tokens.bscs,
  // 	earningToken: tokens.tribeone,
  // 	contractAddress: '0xb86AC944bEECaB78d7ab49cA71b27d25363E6251',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 11767850,
  // 	endBlock: 15211850,
  // 	tokenPerBlock: 43400000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	// 	disUnstake: false,
  // 	// 	disStake: false,
  // 	// 	disHarvest: false,
  // 	typePool: 1,
  // },

  // {
  //   sousId: 60,
  //   stakingToken: tokens.tribeone,
  //   earningToken: tokens.tribeone,
  //   contractAddress: "0x9321838064eE23a5e81d8B832eDA8a56435d0C67",
  //   fees: 2,
  //   blockPeriod: 86400,
  //   startBlock: 11767850,
  //   endBlock: 15211850,
  //   unstakeBlock: 12611850,
  //   harvestBlock: 12611850,
  //   tokenPerBlock: 101270000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   // 	disUnstake: false,
  //   // 	disStake: false,
  //   // 	disHarvest: false,
  //   lockup: 30,
  //   typePool: 1,
  //   chain: 0,
  // },
  // {
  // 	sousId: 58,
  // 	stakingToken: tokens.kws,
  // 	earningToken: tokens.kws,
  // 	contractAddress: '0x9989eFc7D3493BEF5a63cD60f92619649F2c915C',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 11583583,
  // 	endBlock: 12083983,
  // 	tokenPerBlock: 16330000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // 	iodPartnesr: true,
  // },
  // {
  // 	sousId: 54,
  // 	stakingToken: tokens.bscs,
  // 	earningToken: tokens.kws,
  // 	contractAddress: '0x9534C17Deb29C4999a2B476B8e5ff5De4F326df8',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 11358073,
  // 	endBlock: 14700073,
  // 	tokenPerBlock: 72300000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // 	countdownt: true,
  // },
  // {
  // 	sousId: 55,
  // 	stakingToken: tokens.kws,
  // 	earningToken: tokens.kws,
  // 	contractAddress: '0x7A0b27A6f5EBAC48727FE8458E6cA787323F22e2',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 11358073,
  // 	endBlock: 14700073,
  // 	tokenPerBlock: 289400000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // 	countdownt: true,
  // },
  // {
  // 	sousId: 15,
  // 	stakingToken: tokens.bscs,
  // 	earningToken: tokens.kingdomgame,
  // 	contractAddress: '0x9Cec597abAD7f62726BDF02627C1d55B78795d61',
  // 	fees: 0,
  // 	blockPeriod: 86400,
  // 	startBlock: 10397332,
  // 	endBlock: 13853332,
  // 	tokenPerBlock: 2025000000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  {
    sousId: 16,
    stakingToken: tokens.kingdomgame,
    earningToken: tokens.bscs,
    contractAddress: "0x9685ecB565a3305F9f0df5d26A319A7d5aB710Ad",
    fees: 0,
    blockPeriod: 86400,
    startBlock: 10397332,
    endBlock: 13853332,
    tokenPerBlock: 86800000000000000,
    isFinished: true,
    stakingLimit: 0,
    // 	disUnstake: false,
    // 	disStake: false,
    // 	disHarvest: false,
    typePool: 1,
    chain: 0,
  },

  // {
  // 	sousId: 49,
  // 	stakingToken: tokens.bscs,
  // 	earningToken: tokens.greenbeli,
  // 	contractAddress: '0x2cdEc23D9a583fE428E798335F0E92B12527A3a4',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 11139069,
  // 	endBlock: 12863069,
  // 	tokenPerBlock: 86800000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  // {
  // 	sousId: 50,
  // 	stakingToken: tokens.greenbeli,
  // 	earningToken: tokens.bscs,
  // 	contractAddress: '0xfF3790BE5f065C82c688129a41aF84D3A61a37Fd',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 11139069,
  // 	endBlock: 12863069,
  // 	tokenPerBlock: 57900000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  // {
  // 	sousId: 52,
  // 	stakingToken: tokens.bscs,
  // 	earningToken: tokens.ydragon,
  // 	contractAddress: '0xa66E0b583c9008a742397b98aD3c323fBcCC197b',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 11167665,
  // 	endBlock: 12030265,
  // 	tokenPerBlock: 230000000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  // {
  // 	sousId: 53,
  // 	stakingToken: tokens.ydragon,
  // 	earningToken: tokens.ydragon,
  // 	contractAddress: '0xf31c2EA98BC87EeC9e824B72a144758e29856A84',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 11167665,
  // 	endBlock: 12030265,
  // 	tokenPerBlock: 230000000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  // {
  // 	sousId: 17,
  // 	stakingToken: tokens.bscs,
  // 	earningToken: tokens.baby,
  // 	contractAddress: '0x5D19F30E0106616E6b443AD3ADE150AE6FdeEe9e',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 10397332,
  // 	endBlock: 11261332,
  // 	tokenPerBlock: 145000000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  {
    sousId: 18,
    stakingToken: tokens.dpet,
    earningToken: tokens.bscs,
    contractAddress: "0x5F352530a5A93ADDfF95a7C4117c92ebc9198aa4",
    fees: 0,
    blockPeriod: 86400,
    startBlock: 10445592,
    endBlock: 13901305,
    tokenPerBlock: 145000000000000000,
    isFinished: true,
    stakingLimit: 0,
    // 	disUnstake: false,
    // 	disStake: false,
    // 	disHarvest: false,
    typePool: 1,
    chain: 0,
  },
  // {
  // 	sousId: 19,
  // 	stakingToken: tokens.bscs,
  // 	earningToken: tokens.dpet,
  // 	contractAddress: '0xCE13F6F3b27A15E5d3D641A84813Fc33D98257f2',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 10557996,
  // 	endBlock: 13146996,
  // 	tokenPerBlock: 1157400000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  // {
  //   sousId: 20,
  //   stakingToken: tokens.revomon,
  //   earningToken: tokens.bscs,
  //   contractAddress: "0x1cb255CFb0E6273c2554F1Ca1Cb3C3B37b2CE554",
  //   fees: 0,
  //   blockPeriod: 86400,
  //   startBlock: 10474407,
  //   endBlock: 13930262,
  //   tokenPerBlock: 86800000000000000,
  //   isFinished: true,
  //   stakingLimit: 0,
  //   // 	disUnstake: false,
  //   // 	disStake: false,
  //   // 	disHarvest: false,
  //   typePool: 1,
  //   chain: 0,
  // },
  // {
  // 	sousId: 21,
  // 	stakingToken: tokens.fara,
  // 	earningToken: tokens.bscs,
  // 	contractAddress: '0xAB13600B0237C73eb30C931B93aDE5B1699321d6',
  // 	fees: 0,
  // 	blockPeriod: 86400,
  // 	startBlock: 10397332,
  // 	endBlock: 12979332,
  // 	tokenPerBlock: 77100000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  // {
  // 	sousId: 22,
  // 	stakingToken: tokens.polycrowns,
  // 	earningToken: tokens.bscs,
  // 	contractAddress: '0x1d0D54d7311973DF07722A9a6543A4269e3cB1bd',
  // 	fees: 0,
  // 	blockPeriod: 86400,
  // 	startBlock: 10397332,
  // 	endBlock: 12125332,
  // 	tokenPerBlock: 289000000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  // {
  // 	sousId: 23,
  // 	stakingToken: tokens.bnu,
  // 	earningToken: tokens.bscs,
  // 	contractAddress: '0x07dC5d44C7Bb974d00cD16F9190f3C8095B8C963',
  // 	fees: 0,
  // 	blockPeriod: 86400,
  // 	startBlock: 10397332,
  // 	endBlock: 12125332,
  // 	tokenPerBlock: 57800000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  // {
  // 	sousId: 24,
  // 	stakingToken: tokens.bscs,
  // 	earningToken: tokens.bnu,
  // 	contractAddress: '0x469C57EA67291b92bDe2BA526879EA08E4701cE6',
  // 	fees: 0,
  // 	blockPeriod: 86400,
  // 	startBlock: 10397332,
  // 	endBlock: 11606932,
  // 	tokenPerBlock: 115700000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  // {
  // 	sousId: 25,
  // 	stakingToken: tokens.bscs,
  // 	earningToken: tokens.polkaplay,
  // 	contractAddress: '0x6b5590B2739c9BbC5FACDd9F56C26c73F8063Eb6',
  // 	fees: 0,
  // 	blockPeriod: 86400,
  // 	startBlock: 10397332,
  // 	endBlock: 11434132,
  // 	tokenPerBlock: 770000000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  {
    sousId: 26,
    stakingToken: tokens.polkaplay,
    earningToken: tokens.bscs,
    contractAddress: "0x36908fA45f070bff96555C0579079125f2209843",
    fees: 0,
    blockPeriod: 86400,
    startBlock: 10397332,
    endBlock: 12125332,
    tokenPerBlock: 57800000000000000,
    isFinished: true,
    stakingLimit: 0,
    // 	disUnstake: false,
    // 	disStake: false,
    // 	disHarvest: false,
    typePool: 1,
    chain: 0,
  },

  // {
  // 	sousId: 27,
  // 	stakingToken: tokens.bscs,
  // 	earningToken: tokens.tfi,
  // 	contractAddress: '0xdEae7c086ba117CB9768B590e96a67d67466C11D',
  // 	fees: 0,
  // 	blockPeriod: 86400,
  // 	startBlock: 10397332,
  // 	endBlock: 11261332,
  // 	tokenPerBlock: 277770000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },
  // {
  // 	sousId: 28,
  // 	stakingToken: tokens.bscs,
  // 	earningToken: tokens.gem,
  // 	contractAddress: '0x41780307Fd82662dc2A166C25A7888f7fb75b216',
  // 	fees: 0,
  // 	blockPeriod: 86400,
  // 	startBlock: 10537607,
  // 	endBlock: 11400438,
  // 	tokenPerBlock: 115700000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: true,
  // 	disHarvest: false,
  // 	typePool: 1,
  // },

  // add farm from >= 500
  // add farm from >= 500
  // add farm from >= 500

  {
    sousId: 29,
    stakingToken: tokens.bscslp,
    earningToken: tokens.bscs,
    contractAddress: "0xCFf637Bc7eb80AcAc69207746a9Ed34A16B0E991",
    fees: 2,
    blockPeriod: 86400,
    startBlock: 10168238,
    endBlock: 11861005,
    tokenPerBlock: 340000000000000000,
    isFinished: true,
    stakingLimit: 0,
    // 	disUnstake: false,
    // 	disStake: false,
    // 	disHarvest: false,
    typeLP: 1,
    typePool: 2,
    chain: 0,
  },
  {
    sousId: 36,
    stakingToken: tokens.bscsbnblp,
    earningToken: tokens.bscs,
    contractAddress: "0x3cFedE1dC2134a53383A435d197ED34dB741936c",
    fees: 2,
    blockPeriod: 86400,
    startBlock: 10397332,
    endBlock: 13555332,
    tokenPerBlock: 434000000000000000,
    isFinished: true,
    stakingLimit: 0,
    // 	disUnstake: false,
    // 	disStake: false,
    // 	disHarvest: false,
    typeLP: 2,
    typePool: 2,
    chain: 0,
  },
  // {
  // 	sousId: 30,
  // 	stakingToken: tokens.crazynftlp,
  // 	earningToken: tokens.crazynft,
  // 	contractAddress: '0x30e8100C45660bc6D97a2529c8463155ef6C0a3e',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 9883817,
  // 	endBlock: 12392417,
  // 	tokenPerBlock: 2976000000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typeLP: 2,
  // 	typePool: 2,
  // },
  // {
  // 	sousId: 31,
  // 	stakingToken: tokens.cfl365lp,
  // 	earningToken: tokens.cfl365,
  // 	contractAddress: '0x82b11C7826c6Ea8c6925258f7E4FaD62498a7f75',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 9911660,
  // 	endBlock: 10719660,
  // 	tokenPerBlock: 2314000000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typeLP: 2,
  // 	typePool: 2,
  // },
  // {
  // 	sousId: 32,
  // 	stakingToken: tokens.kdglp,
  // 	earningToken: tokens.kingdomgame,
  // 	contractAddress: '0x07FD6a0E3F9dCeb536c428E06B8e566197Deaa2B',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 9996082,
  // 	endBlock: 13331082,
  // 	tokenPerBlock: 1450000000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typeLP: 2,
  // 	typePool: 2,
  // },
  // {
  // 	sousId: 33,
  // 	stakingToken: tokens.vltylp,
  // 	earningToken: tokens.vlty,
  // 	contractAddress: '0x9642C7be31f103a0B2466E6420FA0b8d5036C448',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 10112847,
  // 	endBlock: 10976047,
  // 	tokenPerBlock: 28900000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typeLP: 1,
  // 	typePool: 2,
  // },
  // {
  // 	sousId: 34,
  // 	stakingToken: tokens.tfilp,
  // 	earningToken: tokens.tfi,
  // 	contractAddress: '0x28c1D1697A7d64D2D0E870F50bb2B72e11493Df3',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 10196755,
  // 	endBlock: 11060055,
  // 	tokenPerBlock: 289000000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typeLP: 2,
  // 	typePool: 2,
  // },
  // pool farm new
  // pool farm new
  // pool farm new
  // pool farm new
  // pool farm new
  {
    sousId: 61,
    stakingToken: tokens.bscslp,
    earningToken: tokens.bscs,
    contractAddress: "0x1d80B4d9afC9472Fc379aDc0fFcEDF32483EDe49",
    fees: 2,
    blockPeriod: 86400,
    startBlock: 12054052,
    endBlock: 15500052,
    tokenPerBlock: 578703700000000000,
    isFinished: true,
    stakingLimit: 0,
    // 	disUnstake: false,
    // 	disStake: false,
    // 	disHarvest: false,
    typeLP: 1,
    typePool: 2,
    chain: 0,
  },
  {
    sousId: 35,
    stakingToken: tokens.bscslp,
    earningToken: tokens.bscs,
    contractAddress: "0x31892B9549a9efC7D2EFAE520D1605e8B653F416",
    fees: 0,
    blockPeriod: 86400,
    startBlock: 10397332,
    endBlock: 12060332,
    tokenPerBlock: 340000000000000000,
    isFinished: true,
    stakingLimit: 0,
    // 	disUnstake: false,
    // 	disStake: false,
    // 	disHarvest: false,
    typeLP: 1,
    typePool: 2,
    chain: 0,
  },

  {
    sousId: 97,
    stakingToken: tokens.bscsbnblp,
    earningToken: tokens.bscs,
    contractAddress: "0x025cA2cE33BB1E2FBDdab528639F4d497863439b",
    fees: 2,
    blockPeriod: 86400,
    startBlock: 13577058,
    endBlock: 17003058,
    tokenPerBlock: 231481500000000000,
    isFinished: true,
    stakingLimit: 0,
    // 	disUnstake: false,
    // 	disStake: false,
    // 	disHarvest: false,
    typeLP: 2,
    typePool: 2,
    chain: 0,
  },
  {
    sousId: 109,
    stakingToken: tokens.bscslp,
    earningToken: tokens.bscs,
    contractAddress: "0x502fcE58657dCC80Fd3404c852D906CD50aC655e",
    fees: 0,
    blockPeriod: 86400,
    startBlock: 15595007,
    endBlock: 19051007,
    tokenPerBlock: 289000000000000000,
    isFinished: true,
    stakingLimit: 0,
    // 	disUnstake: false,
    // 	disStake: false,
    // 	disHarvest: false,
    typeLP: 1,
    typePool: 2,
    chain: 0,
  },
  {
    sousId: 118,
    stakingToken: tokens.istepbusdlp,
    earningToken: tokens.istep,
    contractAddress: "0xF39ab3E47484459040E3a7A1B877cA39A260c2AC",
    fees: 0,
    blockPeriod: 86400,
    startBlock: 18253948,
    endBlock: 19981948,
    tokenPerBlock: 694444400000000000,
    isFinished: true,
    stakingLimit: 0,
    typeLP: 1,
    typePool: 2,
    cakeLP: true,
    lockup: 7,
    userLock: true,
    versionSC: 3,
    chain: 0,
  },
  {
    sousId: 115,
    stakingToken: tokens.bscdbnblp,
    earningToken: tokens.bsdium,
    contractAddress: "0xC1E2d6F630B1Ea8B0E4C4E80B3410008Fe12f594",
    fees: 0,
    blockPeriod: 86400,
    startBlock: 16990851,
    endBlock: 20446851,
    tokenPerBlock: 3472200000000000000,
    isFinished: true,
    stakingLimit: 0,
    typeLP: 2,
    typePool: 2,
    cakeLP: true,
    lockup: 7,
    userLock: true,
    versionSC: 3,
    chain: 0,
  },
  {
    sousId: 108,
    stakingToken: tokens.zwzbnblp,
    earningToken: tokens.zwz,
    contractAddress: "0x18Fc522e13c8dd2E86223CA60a5a5E131f0Ea40c",
    fees: 2,
    blockPeriod: 86400,
    startBlock: 15383381,
    endBlock: 18820743,
    tokenPerBlock: 145000000000000000,
    isFinished: true,
    stakingLimit: 0,
    typeLP: 2,
    typePool: 2,
    cakeLP: true,
    lockup: 15,
    userLock: true,
    versionSC: 3,
    chain: 0,
  },
  {
    sousId: 101,
    stakingToken: tokens.dalbusdlp,
    earningToken: tokens.dal,
    contractAddress: "0xB801D820852010B46bCbd2c85E9cde07052FE17A",
    fees: 2,
    blockPeriod: 86400,
    startBlock: 14045129,
    endBlock: 50000648,
    tokenPerBlock: 4340000000000000,
    isFinished: true,
    stakingLimit: 0,
    typeLP: 1,
    typePool: 2,
    cakeLP: true,
    lockup: 15,
    userLock: true,
    versionSC: 3,
    chain: 0,
  },
  // {
  // 	sousId: 96,
  // 	stakingToken: tokens.nextbusdlp,
  // 	earningToken: tokens.next,
  // 	contractAddress: '0xe3121c505a7C1A7D637412a57f6Ab9ea0e6F785D',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 13298446,
  // 	endBlock: 13946446,
  // 	unstakeBlock: 13622446,
  // 	tokenPerBlock: 231481500000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typeLP: 1,
  // 	typePool: 2,
  // 	cakeLP: true,
  // 	lockup: 15,
  // },
  // {
  // 	sousId: 93,
  // 	stakingToken: tokens.btlusdclp,
  // 	earningToken: tokens.btl,
  // 	contractAddress: '0x324208F825D8f470Fe605fB56281e7E8b0B1250a',
  // 	fees: 0,
  // 	blockPeriod: 86400,
  // 	startBlock: 13274668,
  // 	endBlock: 14930606,
  // 	tokenPerBlock: 752314800000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: true,
  // 	disHarvest: false,
  // 	typeLP: 3,
  // 	typePool: 2,
  // 	cakeLP: true,
  // 	linkLP:
  // 		'https://pancakeswap.finance/add/0x51e7B598C9155b9DCcB04Eb42519F6EeC9C841e9/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  // },
  // {
  // 	sousId: 82,
  // 	stakingToken: tokens.realbnblp,
  // 	earningToken: tokens.real,
  // 	contractAddress: '0xEC1B664cB09105802C7c0aB7586A41FBAD9D07b5',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 12987301,
  // 	endBlock: 15422688,
  // 	tokenPerBlock: 579000000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typeLP: 2,
  // 	typePool: 2,
  // 	cakeLP: true,
  // },
  // {
  // 	sousId: 77,
  // 	stakingToken: tokens.hwlbusdlp,
  // 	earningToken: tokens.hwl,
  // 	contractAddress: '0x57566F809D9eAFc1c5D9fBBbEe51f814a2eC6Bf5',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 12827500,
  // 	endBlock: 15400500,
  // 	tokenPerBlock: 385802500000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typeLP: 1,
  // 	typePool: 2,
  // 	cakeLP: true,
  // },
  // {
  // 	sousId: 68,
  // 	stakingToken: tokens.cogibnblp,
  // 	earningToken: tokens.cogi,
  // 	contractAddress: '0xf06747435Ce1761EC389030Fd63C35F956951EbB',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 12699637,
  // 	endBlock: 15200024,
  // 	tokenPerBlock: 192901200000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typeLP: 2,
  // 	typePool: 2,
  // 	cakeLP: true,
  // },
  // {
  // 	sousId: 67,
  // 	stakingToken: tokens.olybnblp,
  // 	earningToken: tokens.oly,
  // 	contractAddress: '0x7584A86e9525d9e45daCa7Dd72A9B6f2BF4889e5',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 12467320,
  // 	endBlock: 15055320,
  // 	tokenPerBlock: 231480000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typeLP: 2,
  // 	typePool: 2,
  // 	cakeLP: true,
  // },
  // {
  // 	sousId: 62,
  // 	stakingToken: tokens.lfwbnblp,
  // 	earningToken: tokens.lfw,
  // 	contractAddress: '0xE40Dd1BF7c0C57d9fe7E2D67E9D8F8e1a5EA291D',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 12418500,
  // 	endBlock: 13279500,
  // 	tokenPerBlock: 23148100000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typeLP: 2,
  // 	typePool: 2,
  // 	cakeLP: true,
  // },
  // {
  // 	sousId: 56,
  // 	stakingToken: tokens.kwsusdtlp,
  // 	earningToken: tokens.kws,
  // 	contractAddress: '0x7b1f753753bd187D08107B312A3991Ee9599B674',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 11358073,
  // 	endBlock: 14700073,
  // 	tokenPerBlock: 1157400000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typeLP: 3,
  // 	typePool: 2,
  // 	countdownt: true,
  // 	cakeLP: true,
  // 	linkLP:
  // 		'https://pancakeswap.finance/add/0x5D0E95C15cA50F13fB86938433269D03112409Fe/0x55d398326f99059fF775485246999027B3197955',
  // },
  // {
  // 	sousId: 57,
  // 	stakingToken: tokens.kwsbnblp,
  // 	earningToken: tokens.kws,
  // 	contractAddress: '0x2a4D0eb224769BCD1468769eAC372E9B5166F0B2',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 11358073,
  // 	endBlock: 14700073,
  // 	tokenPerBlock: 289400000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typeLP: 2,
  // 	typePool: 2,
  // 	countdownt: true,
  // },
  // {
  // 	sousId: 37,
  // 	stakingToken: tokens.kdglp,
  // 	earningToken: tokens.kingdomgame,
  // 	contractAddress: '0xdeBbe71d94E4E2b2AAd016D9a3bF8018ACD3F5Ad',
  // 	fees: 0,
  // 	blockPeriod: 86400,
  // 	startBlock: 10397332,
  // 	endBlock: 13853332,
  // 	tokenPerBlock: 1450000000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typeLP: 2,
  // 	typePool: 2,
  // },

  // {
  // 	sousId: 51,
  // 	stakingToken: tokens.grbebusdlp,
  // 	earningToken: tokens.greenbeli,
  // 	contractAddress: '0x53F6d4eb57d9ab4BAa623bf1BEd641295DE1B606',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 11139069,
  // 	endBlock: 12863069,
  // 	tokenPerBlock: 86800000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typeLP: 1,
  // 	typePool: 2,
  // },
  {
    sousId: 38,
    stakingToken: tokens.babylp,
    earningToken: tokens.bscs,
    contractAddress: "0x47b79E4553B1b4BE532EbBE41E39b1E6fc93c2E3",
    fees: 2,
    blockPeriod: 86400,
    startBlock: 10397332,
    endBlock: 11231332,
    tokenPerBlock: 194440000000000000,
    isFinished: true,
    stakingLimit: 0,
    // 	disUnstake: false,
    // 	disStake: false,
    // 	disHarvest: false,
    typeLP: 1,
    typePool: 2,
    chain: 0,
  },
  // {
  // 	sousId: 39,
  // 	stakingToken: tokens.crazynftlp,
  // 	earningToken: tokens.crazynft,
  // 	contractAddress: '0xA15F06C0be75988A12AeafF612f4fEE0963b32bd',
  // 	fees: 0,
  // 	blockPeriod: 86400,
  // 	startBlock: 10397332,
  // 	endBlock: 12700332,
  // 	tokenPerBlock: 2976000000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typeLP: 2,
  // 	typePool: 2,
  // },
  // {
  // 	sousId: 40,
  // 	stakingToken: tokens.cfl365lp,
  // 	earningToken: tokens.cfl365,
  // 	contractAddress: '0x721310071bdBF4F3Cf85D0f1d6dc30dE93c01D3c',
  // 	fees: 0,
  // 	blockPeriod: 86400,
  // 	startBlock: 10397332,
  // 	endBlock: 11083532,
  // 	tokenPerBlock: 2360000000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typeLP: 2,
  // 	typePool: 2,
  // },
  // {
  // 	sousId: 41,
  // 	stakingToken: tokens.bscslp,
  // 	earningToken: tokens.vlty,
  // 	contractAddress: '0x76227A1A2A7924952C340f7151a92BBfFaa5616D',
  // 	fees: 0,
  // 	blockPeriod: 86400,
  // 	startBlock: 10397332,
  // 	endBlock: 11201732,
  // 	tokenPerBlock: 28900000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typeLP: 1,
  // 	typePool: 2,
  // },

  // {
  // 	sousId: 42,
  // 	stakingToken: tokens.tfilp,
  // 	earningToken: tokens.tfi,
  // 	contractAddress: '0x32f3498dA7e9f8062918abfc8B36Df64766F4bD7',
  // 	fees: 0,
  // 	blockPeriod: 86400,
  // 	startBlock: 10397332,
  // 	endBlock: 11260332,
  // 	tokenPerBlock: 277700000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typeLP: 2,
  // 	typePool: 2,
  // },
  // {
  // 	sousId: 43,
  // 	stakingToken: tokens.gembnblp,
  // 	earningToken: tokens.gem,
  // 	contractAddress: '0x4B6a4A0e9af220A85bCb399b894459FCaD1C6CA2',
  // 	fees: 2,
  // 	blockPeriod: 86400,
  // 	startBlock: 10537607,
  // 	endBlock: 11400438,
  // 	tokenPerBlock: 11570000000000000,
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	typeLP: 2,
  // 	typePool: 2,
  // },
  // IDO pool
  // IDO pool
  // IDO pool

  {
    sousId: 44,
    stakingToken: tokens.bscs,
    earningToken: tokens.bscs,
    contractAddress: "0x9d1933c1b85ff8bcde9febbc0562f7342d0b42d6",
    fees: 2,
    blockPeriod: 86400,
    startBlock: 11139069,
    endBlock: 16313069,
    unstakeBlock: 16313069,
    tokenPerBlock: 675000000000000000, // +6
    isFinished: true,
    stakingLimit: 0,
    // 	disUnstake: false,
    // 	disStake: false,
    // 	disHarvest: false,
    idoLP: false,
    lockup: 180,
    fixapy: 90,
    typePool: 3,
    chain: 0,
  },
  {
    sousId: 45,
    stakingToken: tokens.bscs,
    earningToken: tokens.bscs,
    contractAddress: "0xaeFA83C71D87551c99546DfB7dB5F713ddcd4E28",
    fees: 2,
    blockPeriod: 86400,
    startBlock: 11139069,
    endBlock: 14592069,
    unstakeBlock: 13730069,
    tokenPerBlock: 361000000000000000, // +4
    isFinished: true,
    stakingLimit: 0,
    disUnstake: false,
    disStake: true,
    disHarvest: false,
    idoLP: false,
    lockup: 90,
    fixapy: 60,
    typePool: 3,
    chain: 0,
  },
  {
    sousId: 46,
    stakingToken: tokens.bscs,
    earningToken: tokens.bscs,
    contractAddress: "0x79d5529976252f2579a21c3cB046001f5fb99333",
    fees: 0,
    blockPeriod: 86400,
    startBlock: 11139069,
    endBlock: 12873069,
    unstakeBlock: 12873069,
    tokenPerBlock: 398000000000000000, // +0
    isFinished: true,
    stakingLimit: 0,
    // 	disUnstake: false,
    // 	disStake: false,
    // 	disHarvest: false,
    idoLP: false,
    lockup: 60,
    fixapy: 30,
    typePool: 3,
    chain: 0,
  },
  {
    sousId: 47,
    stakingToken: tokens.idobscsbnblp,
    earningToken: tokens.bscs,
    contractAddress: "0x4CCc37AE6BAAf1Af67899cF8cf0c809Af31d7e7c",
    fees: 0,
    blockPeriod: 86400,
    startBlock: 11139069,
    endBlock: 12873069,
    unstakeBlock: 12873069,
    tokenPerBlock: 92900000000000000,
    isFinished: true,
    stakingLimit: 0,
    disUnstake: false,
    disStake: true,
    disHarvest: false,
    idoLP: true,
    lockup: 60,
    fixapy: 45,
    typePool: 3,
    chain: 0,
  },
  {
    sousId: 48,
    stakingToken: tokens.idobscsbusdlp,
    earningToken: tokens.bscs,
    contractAddress: "0xA4D8348d574FCe65A46b34Efce5952b6158c1787",
    fees: 0,
    blockPeriod: 86400,
    startBlock: 11139069,
    endBlock: 12873069,
    unstakeBlock: 12873069,
    tokenPerBlock: 193000000000000000, // 0.05236
    isFinished: true,
    stakingLimit: 0,
    disUnstake: false,
    disStake: true,
    disHarvest: false,
    idoLP: true,
    lockup: 60,
    fixapy: 45,
    typePool: 3,
    chain: 0,
  },

  {
    sousId: 124,
    stakingToken: tokens.bscs,
    earningToken: tokens.bscs,
    contractAddress: "0x4350E20A6dd4fe18942c65a1A764FFA879230541",
    fees: 0,
    blockPeriod: 86400,
    startBlock: 26560432,
    endBlock: 34852493,
    tokenPerBlock: 0, // +0
    isFinished: true,
    stakingLimit: 0,
    // 	disUnstake: false,
    // 	disStake: false,
    // 	disHarvest: false,
    idoLP: false,
    lockup: 48,
    typePool: 3,
    userLock: true,
    chain: 0,
    birthday: true
  },
  {
    sousId: 98,
    stakingToken: tokens.bscs,
    earningToken: tokens.bscs,
    contractAddress: "0x0b8c29319e69FE053f27585dA2F6f924E745F6cD",
    fees: 0,
    blockPeriod: 86400,
    startBlock: 13662237,
    endBlock: 77006753,
    tokenPerBlock: 85000000000000000, // +0
    isFinished: false,
    stakingLimit: 0,
    // 	disUnstake: false,
    // 	disStake: false,
    // 	disHarvest: false,
    idoLP: false,
    lockup: 45,
    typePool: 3,
    userLock: true,
    chain: 0,
  },
  {
    sousId: 79,
    stakingToken: tokens.bscs,
    earningToken: tokens.bscs,
    contractAddress: "0xCAf51D8B12B3333774191A08DFCaF5718a2E34B4",
    fees: 0,
    blockPeriod: 86400,
    startBlock: 12872069,
    endBlock: 77006753,
    tokenPerBlock: 85000000000000000, // +0
    isFinished: false,
    stakingLimit: 0,
    // 	disUnstake: false,
    // 	disStake: false,
    // 	disHarvest: false,
    idoLP: false,
    lockup: 60,
    typePool: 3,
    userLock: true,
    chain: 0,
  },
  {
    sousId: 120,
    stakingToken: tokens.bscs,
    earningToken: tokens.bscs,
    contractAddress: "0xD67Fd740e0FbeD8367d9146ba177Ff3b22fbE12C",
    fees: 0,
    blockPeriod: 86400,
    startBlock: 25320047,
    endBlock: 40220047,
    tokenPerBlock: 185000000000000000, // +0
    isFinished: false,
    stakingLimit: 0,
    // 	disUnstake: false,
    // 	disStake: false,
    // 	disHarvest: false,
    idoLP: false,
    lockup: 180,
    typePool: 3,
    userLock: true,
    chain: 0,
  },
  {
    sousId: 80,
    stakingToken: tokens.idobscsbnblp,
    earningToken: tokens.bscs,
    contractAddress: "0x3036f3195Cf84a3d95a0dDE8bDf137aE7FE7985E",
    fees: 0,
    blockPeriod: 86400,
    startBlock: 12872069,
    endBlock: 16308000648,
    tokenPerBlock: 173600000000000000,
    isFinished: true,
    stakingLimit: 0,
    // 	disUnstake: false,
    // 	disStake: false,
    // 	disHarvest: false,
    idoLP: true,
    lockup: 60,
    typePool: 3,
    userLock: true,
    chain: 0,
  },
  {
    sousId: 81,
    stakingToken: tokens.idobscsbusdlp,
    earningToken: tokens.bscs,
    contractAddress: "0x63053C30C2aF5747a0B0527ea6304a1dE7Ccd3A5",
    fees: 0,
    blockPeriod: 86400,
    startBlock: 12872069,
    endBlock: 16308000648,
    tokenPerBlock: 173600000000000000, // 0.05236
    isFinished: true,
    stakingLimit: 0,
    // 	disUnstake: false,
    // 	disStake: false,
    // 	disHarvest: false,
    idoLP: true,
    lockup: 60,
    typePool: 3,
    userLock: true,
    chain: 0,
  },
  
  {
    sousId: 121,
    stakingToken: tokens.bscs,
    earningToken: tokens.terz,
    contractAddress: "0x9274a9CceEe8e4aF9607680c038741c87B381e65",
    fees: 0,
    blockPeriod: 86400,
    startBlock: 26146010,
    endBlock: 27441692,
    tokenPerBlock: 413350000000000000, // +0
    isFinished: false,
    stakingLimit: 0,
    idoLP: false,
    lockup: 30,
    typePool: 1,
    userLock: true,
    chain: 0,
    totalRewards: '2,142,857',
    dailyRewards: '47,619'
  },{
    sousId: 122,
    stakingToken: tokens.terz,
    earningToken: tokens.terz,
    contractAddress: "0xdD2659da1CB4163D00DbF8a6D0560526DBEe8e4c",
    fees: 0,
    blockPeriod: 86400,
    startBlock: 26146010,
    endBlock: 28737692,
    tokenPerBlock: 413350000000000000, // +0
    isFinished: false,
    stakingLimit: 0,
    idoLP: false,
    lockup: 30,
    typePool: 1,
    userLock: true,
    chain: 0,
    totalRewards: '4,285,714',
    dailyRewards: '47,619'
  },
  {
    sousId: 123,
    stakingToken: tokens.terz,
    earningToken: tokens.terz,
    contractAddress: "0x2ce1CC2FC6280BB01ce6d941c0E695E399fD7DB8",
    fees: 0,
    blockPeriod: 86400,
    startBlock: 26146010,
    endBlock: 31330010,
    tokenPerBlock: 378900000000000000, // +0
    isFinished: false,
    stakingLimit: 0,
    idoLP: false,
    lockup: 60,
    typePool: 1,
    userLock: true,
    chain: 0,
    totalRewards: '7,857,143',
    dailyRewards: '43,650'
  },

  // {
  // 	sousId: 78,
  // 	stakingToken: tokens.bscs,
  // 	earningToken: tokens.bscs,
  // 	contractAddress: '0xC12a8c3Fe8B65B43aADb1Bb030E29c75677bE761',
  // 	fees: 0,
  // 	blockPeriod: 86400,
  // 	startBlock: 12860818,
  // 	endBlock: 16308000648,
  // 	tokenPerBlock: 193000000000000, // 0.05236
  // 	isFinished: true,
  // 	stakingLimit: 0,
  // 	Inactive: false,
  // 	disUnstake: false,
  // 	disStake: false,
  // 	disHarvest: false,
  // 	idoLP: false,
  // 	lockup: 44444444444,
  // 	fixapy: 45,
  // 	typePool: 3,
  // 	userLock: true,
  // },
];
// sousId lớn nhất : 124
//  ido pool :sousId = 124
//  start pool :sousId = 123
//  farm pool :sousId = 101
// contract test 0x1818233Dcb4C0919cF256De06C007DE2D2563111
export default poolsConfig;
