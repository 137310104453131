import { useCallback } from 'react'
import useWeb3 from '../hooks/useWeb3'
import { sousUnstakeUserLock } from '../utils/callHelpers'
import { getSousChefContract } from '../utils/contractHelpers'
import { useWallet } from 'use-wallet'

const useSousUnstakeUserLock = (sousId) => {
	const { account } = useWallet()
	const web3 = useWeb3()

	const handleUnstakeUserLock = useCallback(
		async (amount, decimals) => {
			const souschefContract = getSousChefContract(sousId, web3)
			const txHash = await sousUnstakeUserLock(
				souschefContract,
				amount,
				decimals,
				account,
			)
		},
		[account, sousId, web3],
	)

	return { onUnstakeLock: handleUnstakeUserLock }
}

export default useSousUnstakeUserLock
