import { useCallback } from 'react'
import useWeb3 from '../hooks/useWeb3'
import { sousUnstakeV3 } from '../utils/callHelpers'
import { getSousChefV3Contract } from '../utils/contractHelpers'
import { useWallet } from 'use-wallet'

const useSousUnstakeV3 = (sousId) => {
	const { account } = useWallet()
	const web3 = useWeb3()

	const handleUnstakeV3 = useCallback(
		async (amount, decimals) => {
			const souschefV3Contract = getSousChefV3Contract(sousId, web3)
			const txHash = await sousUnstakeV3(
				souschefV3Contract,
				amount,
				decimals,
				account,
			)
		},
		[account, sousId, web3],
	)

	return { onUnstakeV3: handleUnstakeV3 }
}

export default useSousUnstakeV3
