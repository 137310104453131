import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import Page from '../../components/Page'
import PageHeader from '../../components/PageHeader'

import FarmCards from './components/FarmCards'
import PoolCardsLP from '../Pools/components/PoolCardsLP'

const Farms: React.FC = () => {
	const { path } = useRouteMatch()
	return (
		<Switch>
			<Page background="/images/bg-earth.png">
				<Route exact path={path}>
					<PageHeader
						title="Farms"
						subtitle1="Stake Liquidity Pool (LP) tokens to earn."
						subtitle2="Rewards are calculated per block. "
					/>
					<PoolCardsLP />
				</Route>
			</Page>
		</Switch>
	)
}

export default Farms
