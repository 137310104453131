import React, { useCallback } from 'react'
import useWeb3 from '../hooks/useWeb3'
import { soushHarvestV3 } from '../utils/callHelpers'
import { getSousChefV3Contract } from '../utils/contractHelpers'
import { useWallet } from 'use-wallet'

const useSousHarvestV3 = (sousId) => {
	const { account } = useWallet()
	const web3 = useWeb3()

	const handleRewardV3 = useCallback(async () => {
		const souschefV3Contract = getSousChefV3Contract(sousId, web3)
		const txHash = await soushHarvestV3(souschefV3Contract, account)
	}, [account, web3])
	return { onRewardV3: handleRewardV3 }
}

export default useSousHarvestV3
