import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import { BIG_TEN } from './bigNumber'
import Web3 from "web3";
const web3 = new Web3(window.ethereum);
export const approve = async (lpContract, masterChefContract, account) => {
	const gasPrice = await web3.eth.getGasPrice();
	return lpContract.methods
		.approve(masterChefContract.options.address, ethers.constants.MaxUint256)
		.send({ from: account, gasPrice: web3.utils.toHex(String(gasPrice)) })
}

export const approveSousChef = async (contract, poolAddress, account) => {
	const gasPrice = await web3.eth.getGasPrice();
	return contract.methods
		.approve(poolAddress, ethers.constants.MaxUint256)
		.send({ from: account, gasPrice: web3.utils.toHex(String(gasPrice)) })
}

export const sousStake = async (
	souschefContract,
	amount,
	decimals,
	account,
) => {
	const gasPrice = await web3.eth.getGasPrice();
	return souschefContract.methods
		.deposit(new BigNumber(amount).times(BIG_TEN.pow(decimals)).toFixed())
		.send({ from: account, gasPrice: web3.utils.toHex(String(gasPrice)) })
		.on('transactionHash', (tx) => {
			return tx.transactionHash
		})
}
export const sousStakeV3 = async (
	souschefContract,
	amount,
	decimals,
	account,
) => {
	const gasPrice = await web3.eth.getGasPrice();
	return souschefContract.methods
		.deposit(new BigNumber(amount).times(BIG_TEN.pow(decimals)).toFixed())
		.send({ from: account, gasPrice: web3.utils.toHex(String(gasPrice)) })
		.on('transactionHash', (tx) => {
			return tx.transactionHash
		})
}

export const unstake = async (masterChefContract, pid, amount, account) => {
	const gasPrice = await web3.eth.getGasPrice();
	return masterChefContract.methods
		.withdraw(pid, ethers.utils.parseUnits(amount, 18))
		.send({ from: account, gasPrice: web3.utils.toHex(String(gasPrice)) })
		.on('transactionHash', (tx) => {
			return tx.transactionHash
		})
}

export const sousUnstake = async (
	souschefContract,
	amount,
	decimals,
	account,
) => {
	const gasPrice = await web3.eth.getGasPrice();
	return souschefContract.methods
		.withdraw(new BigNumber(amount).times(BIG_TEN.pow(decimals)).toFixed())
		.send({ from: account, gasPrice: web3.utils.toHex(String(gasPrice)) })
		.on('transactionHash', (tx) => {
			return tx.transactionHash
		})
}
export const sousUnstakeUserLock = async (
	souschefContract,
	amount,
	decimals,
	account,
) => {
	const gasPrice = await web3.eth.getGasPrice();
	return souschefContract.methods
		.withdrawNew(false)
		.send({ from: account, gasPrice: web3.utils.toHex(String(gasPrice)) })
		.on('transactionHash', (tx) => {
			return tx.transactionHash
		})
}
export const sousUnstakeV3 = async (
	souschefContract,
	amount,
	decimals,
	account,
) => {
	const gasPrice = await web3.eth.getGasPrice();
	return souschefContract.methods
		.withdraw(false)
		.send({ from: account, gasPrice: web3.utils.toHex(String(gasPrice)) })
		.on('transactionHash', (tx) => {
			return tx.transactionHash
		})
}

export const harvest = async (masterChefContract, pid, account) => {
	const gasPrice = await web3.eth.getGasPrice();
	return masterChefContract.methods
		.deposit(pid, '0')
		.send({ from: account, gasPrice: web3.utils.toHex(String(gasPrice)) })
		.on('transactionHash', (tx) => {
			return tx.transactionHash
		})
}

export const soushHarvest = async (souschefContract, account) => {
	const gasPrice = await web3.eth.getGasPrice();
	return souschefContract.methods
		.withdraw('0')
		.send({ from: account, gasPrice: web3.utils.toHex(String(gasPrice)) })
		.on('transactionHash', (tx) => {
			return tx.transactionHash
		})
}
export const soushHarvestUserLock = async (souschefContract, account) => {
	const gasPrice = await web3.eth.getGasPrice();
	return souschefContract.methods
		.withdrawNew(true)
		.send({ from: account, gasPrice: web3.utils.toHex(String(gasPrice)) })
		.on('transactionHash', (tx) => {
			return tx.transactionHash
		})
}
export const soushHarvestV3 = async (souschefContract, account) => {
	const gasPrice = await web3.eth.getGasPrice();
	return souschefContract.methods
		.withdraw(true)
		.send({ from: account, gasPrice: web3.utils.toHex(String(gasPrice)) })
		.on('transactionHash', (tx) => {
			return tx.transactionHash
		})
}
export const getEarned = async (masterChefContract, pid, account) => {
	return masterChefContract.methods.pendingRewards(pid, account).call()
}

export const getRewardPerBlock = async (masterChefContract) => {
	return masterChefContract.methods.rewardPerBlock().call()
}

export const getStaked = async (masterChefContract, pid, account) => {
	try {
		const { amount } = await masterChefContract.methods
			.userInfo(pid, account)
			.call()
		return new BigNumber(amount)
	} catch {
		return new BigNumber(0)
	}
}
