import React from 'react'
import { Clock, Target } from 'react-feather'
import Value from '../../../components/Value'
import { useCurrentBlock } from '../../../store/hooks'
import { getPoolBlockUnstake, getPoolBlockUserLock } from '../helpers'
import Countdown from 'react-countdown'
import { color } from 'theme/colors'

const BlockCountdownUnstake = ({ pool }) => {
	const currentBlock = useCurrentBlock()

	const {
		shouldShowBlockCountdown,
		blocksUntilStart,
		blocksRemaining,
		hasPoolStarted,
		blocksToDisplay,
	} = getPoolBlockUnstake(pool, currentBlock)
	const {
		blocksUntilStartUserLock,
		blocksRemainingUserLock,
		hasPoolStartedUserLock,
		blocksToDisplayUserLock,
	} = getPoolBlockUserLock(pool, currentBlock)

	var timeS = blocksToDisplay * 3
	const renderer = ({ days, hours, minutes, seconds, completed }) => {
		if (completed) {
			return
		} else {
			return (
				<span>
					{days}d : {hours} : {minutes} : {seconds}
				</span>
			)
		}
	}
	return (
		<>
			{pool.userLock ? (
				<>
					<div className="text-white d-flex justify-content-between flex-wrap align-items-center">
						<p className="text-total-staked remaining">
							{hasPoolStartedUserLock ? (
								'Rewards end in'
							) : (
								<>
									{(blocksRemainingUserLock || blocksUntilStartUserLock) &&
									currentBlock
										? 'IDO pool start in'
										: 'Unlocked'}
								</>
							)}
						</p>
						<div className="d-flex align-items-center">
							{(blocksRemainingUserLock || blocksUntilStartUserLock) &&
							currentBlock ? (
								<>
									<Value
										size={14}
										value={blocksToDisplayUserLock}
										decimals={0}
									/>
									<span className="ml-1">Blocks</span>
									{hasPoolStartedUserLock ? (
										<a
											href={`https://bscscan.com/block/countdown/${pool.endBlock}`}
											target="_blank"
											rel="noreferrer"
											className="text-white"
										>
											<Clock className="ml-1 clock-block-details" size={16} />
										</a>
									) : (
										<a
											href={`https://bscscan.com/block/countdown/${pool.startBlock}`}
											target="_blank"
											rel="noreferrer"
											className="text-white"
										>
											<Clock className="ml-1 clock-block-details" size={16} />
										</a>
									)}
								</>
							) : (
								''
							)}
						</div>
					</div>
				</>
			) : (
				<>
					{shouldShowBlockCountdown && (
						<div className="text-white d-flex justify-content-between flex-wrap align-items-center">
							<p className="text-total-staked remaining">
								{hasPoolStarted ? (
									'Remaining unlock'
								) : (
									<>
										{(blocksRemaining || blocksUntilStart) && currentBlock
											? 'IDO pool start in'
											: 'Unlocked'}
									</>
								)}
							</p>
							<div className="d-flex align-items-center">
								{(blocksRemaining || blocksUntilStart) && currentBlock ? (
									<>
										{/* <Countdown date={Date.now() + timeS * 1000} renderer={renderer} /> */}
										<Value size={14} value={blocksToDisplay} decimals={0} />
										<span className="ml-1">Blocks</span>
										{hasPoolStarted ? (
											<a
												href={`https://bscscan.com/block/countdown/${pool.unstakeBlock}`}
												target="_blank"
												rel="noreferrer"
												className="text-white"
											>
												<Clock className="ml-1 clock-block-details" size={16} />
											</a>
										) : (
											<a
												href={`https://bscscan.com/block/countdown/${pool.startBlock}`}
												target="_blank"
												rel="noreferrer"
												className="text-white"
											>
												<Clock className="ml-1 clock-block-details" size={16} />
											</a>
										)}
									</>
								) : (
									''
								)}
							</div>
						</div>
					)}
				</>
			)}
		</>
	)
}

export default BlockCountdownUnstake
