export const getPoolBlockInfo = (pool, currentBlock) => {
	const { startBlock, endBlock, isFinished } = pool

	const shouldShowBlockCountdown = Boolean(
		!isFinished && startBlock && endBlock,
	)
	const blocksUntilStart = Math.max(startBlock - currentBlock, 0)
	const blocksRemaining = Math.max(endBlock - currentBlock, 0)
	const hasPoolStarted = blocksUntilStart === 0 && blocksRemaining > 0
	const blocksToDisplay = hasPoolStarted ? blocksRemaining : blocksUntilStart
	return {
		shouldShowBlockCountdown,
		blocksUntilStart,
		blocksRemaining,
		hasPoolStarted,
		blocksToDisplay,
	}
}
export const getPoolBlockUnstake = (pool, currentBlock) => {
	const { startBlock, unstakeBlock, isFinished } = pool

	const shouldShowBlockCountdown = Boolean(
		!isFinished && startBlock && unstakeBlock,
	)
	const blocksUntilStart = Math.max(startBlock - currentBlock, 0)
	const blocksRemaining = Math.max(unstakeBlock - currentBlock, 0)
	const hasPoolStarted = blocksUntilStart === 0 && blocksRemaining > 0
	const blocksToDisplay = hasPoolStarted ? blocksRemaining : blocksUntilStart
	return {
		shouldShowBlockCountdown,
		blocksUntilStart,
		blocksRemaining,
		hasPoolStarted,
		blocksToDisplay,
	}
}
export const getPoolBlockUserLock = (pool, currentBlock) => {
	const { startBlock, endBlock, isFinished } = pool

	const shouldShowBlockCountdown = Boolean(
		!isFinished && startBlock && endBlock,
	)
	const blocksUntilStartUserLock = Math.max(startBlock - currentBlock, 0)
	const blocksRemainingUserLock = Math.max(endBlock - currentBlock, 0)
	const hasPoolStartedUserLock =
		blocksUntilStartUserLock === 0 && blocksRemainingUserLock > 0
	const blocksToDisplayUserLock = hasPoolStartedUserLock
		? blocksRemainingUserLock
		: blocksUntilStartUserLock
	return {
		shouldShowBlockCountdown,
		blocksUntilStartUserLock,
		blocksRemainingUserLock,
		hasPoolStartedUserLock,
		blocksToDisplayUserLock,
	}
}
