// import React from 'react'
import React, { useState, useRef } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { Switch, Input, Space } from 'antd'
import PoolCardList from './PoolCardList'
import { usePools } from '../../../store/hooks'
import { useWallet } from 'use-wallet'
import './style.css'
import arrowRight from './images/Chevron_Right.png'

const PoolCards = () => {
	const { account } = useWallet()
	const { pools, userDataLoaded } = usePools(account)

	const [defaultChecked, setdefaultChecked] = useState(false)
	function onChange(checked) {
		if (checked === true) {
			setdefaultChecked(true)
		} else {
			setdefaultChecked(false)
		}
	}
	const [searchQuery, setSearchQuery] = useState('')
	const handleChangeSearchQuery = (e) => {
		setSearchQuery(e.target.value)
	}
	const tableWrapperEl = useRef(null)
	const scrollToTop = () => {
		tableWrapperEl.current.scrollIntoView({
			behavior: 'smooth',
		})
	}
	return (
		<>
			<div className="main-pool-card" role="table" ref={tableWrapperEl}>
				<div className="stake-only">
					<Switch onChange={onChange} />
					<span className="text-stake-only">Staked only</span>
				</div>
				<div className="box-search-stake">
					<Input
						type="text"
						placeholder="Search token"
						onChange={handleChangeSearchQuery}
					/>
				</div>
				<Tabs defaultActiveKey="finished" transition={false}>
					<Tab className="bsc-nav-tabs" disabled eventKey="live" title="Live">
						<Tabs
							defaultActiveKey="list"
							id="uncontrolled-tab-example"
							className="box-list-grid"
						>
							<Tab eventKey="list">
								<div>
									<>
										{pools
											.filter((pool) => pool.isFinished === false)
											.map((pool, index) => (
												<PoolCardList
													key={index}
													pool={pool}
													userDataLoaded={userDataLoaded}
												/>
											))}
									</>
								</div>
								<br />
								
							</Tab>
						</Tabs>
					</Tab>
					<Tab className="bsc-nav-tabs" eventKey="finished" title="Finished">
						<>
							<Tabs
								defaultActiveKey="list"
								id="uncontrolled-tab-example"
								className="box-list-grid"
							>
								<Tab eventKey="list">
									<div>
										{defaultChecked === true ? (
											<>
												{pools
													.filter(
														(pool) =>
															pool.userData.stakedBalance > 0 &&
															pool.isFinished === true &&
															(pool.earningToken.symbol
																.toLowerCase()
																.includes(searchQuery.toLowerCase()) ||
																pool.stakingToken.symbol
																	.toLowerCase()
																	.includes(searchQuery.toLowerCase())),
													)
													.map((pool, index) => (
														<PoolCardList
															key={index}
															pool={pool}
															userDataLoaded={userDataLoaded}
														/>
													))}
											</>
										) : (
											<>
												{pools
													.filter(
														(pool) =>
															pool.isFinished === true &&
															(pool.earningToken.symbol
																.toLowerCase()
																.includes(searchQuery.toLowerCase()) ||
																pool.stakingToken.symbol
																	.toLowerCase()
																	.includes(searchQuery.toLowerCase())),
													)
													.map((pool, index) => (
														<PoolCardList
															key={index}
															pool={pool}
															userDataLoaded={userDataLoaded}
														/>
													))}
											</>
										)}
									</div>
									<br />
									<div className="back-to-top">
										<button type="button" onClick={scrollToTop}>
											To Top <img src={arrowRight} alt="" />
										</button>
									</div>
									<br />
								</Tab>
							</Tabs>
						</>
					</Tab>
				</Tabs>
			</div>
		</>
	)
}

export default PoolCards
