const tokens = {
	// add pool stake 
	bscs: {
		symbol: 'BSCS',
		address: '0xbcb24afb019be7e93ea9c43b7e22bb55d5b7f45d',
		decimals: 18,
		projectLink: 'https://bscs.finance/',
	},
	dpet: {
		symbol: 'DPET',
		address: '0xfb62ae373aca027177d1c18ee0862817f9080d08',
		decimals: 18,
		projectLink: 'https://mydefipet.com/',
	},
	revomon: {
		symbol: 'REVO',
		address: '0x155040625d7ae3e9cada9a73e3e44f76d3ed1409',
		decimals: 18,
		projectLink: 'https://revomon.io/',
	},
	fara: {
		symbol: 'FARA',
		address: '0xF4Ed363144981D3A65f42e7D0DC54FF9EEf559A1',
		decimals: 18,
		projectLink: 'https://faraland.io/',
	},

	polycrowns: {
		symbol: 'CWS',
		address: '0xbcf39f0edda668c58371e519af37ca705f2bfcbd',
		decimals: 18,
		projectLink: 'https://game.seascape.network/',
	},
	bnu: {
		symbol: 'BNU',
		address: '0x4954e0062e0a7668a2fe3df924cd20e6440a7b77',
		decimals: 18,
		projectLink: 'https://bytenext.io/',
	},
	cfl365: {
		symbol: 'CFL365',
		address: '0xc1e0510a0df7646817b6632d32caa681a425a5e6',
		decimals: 18,
		projectLink: 'https://www.cfl365.finance/',
	},

	crazynft: {
		symbol: '2CRZ',
		address: '0x3a6e8b36645d6c252714daddd28ec0673535a109',
		decimals: 18,
		projectLink: 'https://www.2crazynft.com/',
	},

	kingdomgame: {
		symbol: 'KDG',
		address: '0x87a2d9a9a6b2d61b2a57798f1b4b2ddd19458fb6',
		decimals: 18,
		projectLink: 'https://kingdomgame.org/',
	},
	polkaplay: {
		symbol: 'POLO',
		address: '0xb28a7f8f5328faffdd862985177583c2bb71e016',
		decimals: 18,
		projectLink: 'https://polkaplay.io/',
	},
	vlty: {
		symbol: 'VLTY',
		address: '0xb35c9eaa5957563594f7411c6ca2755640253c15',
		decimals: 18,
		projectLink: 'https://www.vaulty.fi/',
	},
	tfi: {
		symbol: 'TFI',
		address: '0x7565ab68d3f9dadff127f864103c8c706cf28235',
		decimals: 18,
		projectLink: 'https://trustfi.org/',
	},
	baby: {
		symbol: 'BABY',
		address: '0x53e562b9b7e5e94b81f10e96ee70ad06df3d2657',
		decimals: 18,
		projectLink: 'https://home.babyswap.finance/',
	},
	gem: {
		symbol: 'GEM',
		address: '0xbac1df744df160877cdc45e13d0394c06bc388ff',
		decimals: 18,
		projectLink: 'https://nftmall.io/',
	},
	greenbeli: {
		symbol: 'GRBE',
		address: '0x8473927b49e6dd0548f8287ea94109b7b753e3cf',
		decimals: 18,
		projectLink: 'https://greenbeli.io/',
	},
	ydragon: {
		symbol: 'YDR',
		address: '0x3757232b55e60da4a8793183ac030cfce4c3865d',
		decimals: 18,
		projectLink: 'https://ydragon.io/',
	},
	kws: {
		symbol: 'KWS',
		address: '0x5d0e95c15ca50f13fb86938433269d03112409fe',
		decimals: 18,
		projectLink: 'https://knightwar.io/',
	},
	tribeone: {
		symbol: 'HAKA',
		address: '0xd85ad783cc94bd04196a13dc042a3054a9b52210',
		decimals: 18,
		projectLink: 'https://tribeone.io/',
	},
	lfw: {
		symbol: 'LFW',
		address: '0xd71239a33c8542bd42130c1b4aca0673b4e4f48b',
		decimals: 18,
		projectLink: 'https://legendfantasywar.com/',
	},
	oly: {
		symbol: 'OLY',
		address: '0x74c1815474a75dcb366223107cde1bba4a1a7296',
		decimals: 18,
		projectLink: 'https://www.olysport.io/',
	},
	cogi: {
		symbol: 'COGI',
		address: '0x6cb755c4b82e11e727c05f697c790fdbc4253957',
		decimals: 18,
		projectLink: 'https://9dnft.com/',
	},
	hwl: {
		symbol: 'HWL',
		address: '0x549cc5df432cdbaebc8b9158a6bdfe1cbd0ba16d',
		decimals: 18,
		projectLink: 'https://www.howlcity.io/',
	},
	real: {
		symbol: 'REAL',
		address: '0xe91cd52bd65fe23a3eae40e3eb87180e8306399f',
		decimals: 18,
		projectLink: 'https://www.realrealm.io/',
	},
	ack: {
		symbol: 'ACK',
		address: '0xf7b5fb4607abfe0ecf332c23cbdcc9e425b443a8',
		decimals: 18,
		projectLink: 'https://acknoledger.com/',
	},
	dnft: {
		symbol: 'DNFT',
		address: '0x162c2332e92be409254ac7c59ad559c16a3f506e',
		decimals: 18,
		projectLink: 'https://darenft.com/',
	},
	btl: {
		symbol: 'BTL',
		address: '0x51e7b598c9155b9dccb04eb42519f6eec9c841e9',
		decimals: 18,
		projectLink: 'https://btl.bitlocus.com/',
	},
	next: {
		symbol: 'NEXT',
		address: '0x9809e877192b510d767a94ba39a79429219a5afb',
		decimals: 18,
		projectLink: 'https://shopnext.io/',
	},
	dal: {
		symbol: 'DAL',
		address: '0x53e4b7aa6caccb9576548be3259e62de4ddd4417',
		decimals: 18,
		projectLink: 'https://daolaunch.net/',
	},
	zwz: {
		symbol: 'ZWZ',
		address: '0x5445451c07e20ba1ca887b6c74d66d358f46d083',
		decimals: 18,
		projectLink: 'https://zombieworldz.io/',
	},
	mst: {
		symbol: 'MST',
		address: '0xe7af3fcc9cb79243f76947402117d98918bd88ea',
		decimals: 18,
		projectLink: 'https://mstation.io/',
	},
	bsdium: {
		symbol: 'BSCD',
		address: '0xe0387845f8289fd5875e7193064392e061f46e58',
		decimals: 18,
		projectLink: 'https://mstation.io/',
	},
	istep: {
		symbol: 'ISTEP',
		address: '0x67343c29c0fd9827f33e675e0eb80773f9444444',
		decimals: 18,
		projectLink: 'https://istep.io/',
	},
	terz: {
		symbol: 'TERZ',
		address: '0xcf3bb6ac0f6d987a5727e2d15e39c2d6061d5bec',
		decimals: 18,
		projectLink: 'https://token.shelterz.ru/',
	},
	// add pool farm

	cfl365lp: {
		symbol: 'CFL365-BNB LP',
		address: '0xe47543952294b1411836d0a5a0335ce601651da3',
		decimals: 18,
		projectLink: 'https://www.cfl365.finance/',
	},
	crazynftlp: {
		symbol: '2CRZ-BNB LP',
		address: '0x4c76108f39b815a3a96f0ced6fb807296670bb43',
		decimals: 18,
		projectLink: 'https://www.2crazynft.com/',
	},
	kdglp: {
		symbol: 'KDG-BNB LP',
		address: '0xc3d3bee26a10a6ce9b7baaf4d953a05db194c2c6',
		decimals: 18,
		projectLink: 'https://kingdomgame.org/',
	},
	vltylp: {
		symbol: 'BSCS-BUSD LP',
		address: '0x61ab11015dd7d575135b27c7e10d063cfbdf7046',
		decimals: 18,
		projectLink: 'https://www.vaulty.fi/',
	},
	bscslp: {
		symbol: 'BSCS-BUSD LP',
		address: '0x61ab11015dd7d575135b27c7e10d063cfbdf7046',
		decimals: 18,
		projectLink: 'https://bscs.finance/',
	},
	tfilp: {
		symbol: 'TFI-BNB LP',
		address: '0x639c6c4874813b47c42ad265f428328cc51b0876',
		decimals: 18,
		projectLink: 'https://trustfi.org/',
	},
	babylp: {
		symbol: 'BABY-BUSD LP',
		address: '0x9aeaf63edf07290371c546b03971e3be71fc5f2a',
		decimals: 18,
		projectLink: 'https://home.babyswap.finance/',
	},
	bscsbnblp: {
		symbol: 'BSCS-BNB LP',
		address: '0xbcb983258088e4513870fe1a87026f92008863f4',
		decimals: 18,
		projectLink: 'https://bscs.finance/',
	},
	gembnblp: {
		symbol: 'GEM-BNB LP',
		address: '0xbb1e5201bf727277062a42f52e40bbd83c0d03e0',
		decimals: 18,
		projectLink: 'https://nftmall.io/',
	},
	ido: {
		symbol: 'IDO',
		address: '0x7df2a33bfcd76240b796b0fbbed406aa1fcce830',
		decimals: 18,
		projectLink: 'https://bscs.finance/',
	},
	idobscsbnblp: {
		symbol: 'BSCS-BNB LP',
		address: '0xbcb983258088e4513870fe1a87026f92008863f4',
		decimals: 18,
		projectLink: 'https://bscs.finance/',
	},
	idobscsbusdlp: {
		symbol: 'BSCS-BUSD LP',
		address: '0x61ab11015dd7d575135b27c7e10d063cfbdf7046',
		decimals: 18,
		projectLink: 'https://bscs.finance/',
	},
	grbebusdlp: {
		symbol: 'GRBE-BUSD LP',
		address: '0xa127254271c02293d388be64cc3d19fec539a07f',
		decimals: 18,
		projectLink: 'https://greenbeli.io/',
	},
	kwsusdtlp: {
		symbol: 'KWS-USDT CAKE LP',
		address: '0x99bf883e494fa8f3fb205f551caf4a51699feb40',
		decimals: 18,
		projectLink: 'https://bscs.finance/',
	},
	kwsbnblp: {
		symbol: 'KWS-BNB LP',
		address: '0x4f4bd2580e14cc79d62923c325da8b7e8676cf07',
		decimals: 18,
		projectLink: 'https://bscs.finance/',
	},
	lfwbnblp: {
		symbol: 'LFW-BNB CAKE LP',
		address: '0xf7f7bbafdb33bfc05dbdad07d9b1fa46df3e868e',
		decimals: 18,
		projectLink: 'https://legendfantasywar.com/',
	},
	olybnblp: {
		symbol: 'OLY-BNB CAKE LP',
		address: '0x95dd195009cfeff55c8e5c4d79f9b3c46eab5080',
		decimals: 18,
		projectLink: 'https://www.olysport.io/',
	},
	cogibnblp: {
		symbol: 'COGI-BNB CAKE LP',
		address: '0xb63b69870ffe20fe1c01492cfc9c38177da8ef0a',
		decimals: 18,
		projectLink: 'https://9dnft.com/',
	},
	hwlbusdlp: {
		symbol: 'HWL-BUSD CAKE LP',
		address: '0x6d662dc7b435f07cc7ec12507f65aab2d5b0d5d5',
		decimals: 18,
		projectLink: 'https://www.howlcity.io/',
	},
	realbnblp: {
		symbol: 'REAL-BNB CAKE LP',
		address: '0xb4d43d7e3b58b0b717605b0d878297b58628c80b',
		decimals: 18,
		projectLink: 'https://www.realrealm.io',
	},
	btlusdclp: {
		symbol: 'BTL-USDC CAKE LP',
		address: '0x157ec5ac9bbbc0b7c953b89c69754c349d233598',
		decimals: 18,
		projectLink: 'https://btl.bitlocus.com/',
	},
	nextbusdlp: {
		symbol: 'NEXT-BUSD CAKE LP',
		address: '0x2345032d10ec5b02dd6f0c31fd42a1c3991c0455',
		decimals: 18,
		projectLink: 'https://shopnext.io/',
	},
	dalbusdlp: {
		symbol: 'DAL-BUSD CAKE LP',
		address: '0x85b3bd40853b42ae24d295cd90a234f01b17a3b6',
		decimals: 18,
		projectLink: 'https://daolaunch.net/',
	},
	zwzbnblp: {
		symbol: 'ZWZ-BNB CAKE LP',
		address: '0x39e4a996a3c4d79abc15d138afdf48aba20e88a1',
		decimals: 18,
		projectLink: 'https://zombieworldz.io/',
	},
	bscdbnblp: {
		symbol: 'BSCD-BNB CAKE LP',
		address: '0x4d3bda763629853c009206b8cbb9a17277f7c388',
		decimals: 18,
		projectLink: 'https://mstation.io/',
	},
	istepbusdlp: {
		symbol: 'ISTEP-BUSD CAKE LP',
		address: '0xf5add5c089d46cb377dabd215e294bf1079394f2',
		decimals: 18,
		projectLink: 'https://istep.io/',
	},
}

export default tokens
