import BigNumber from 'bignumber.js'
import React, { useEffect, useCallback, useState } from 'react'
import useSousHarvest from '../../../hooks/useSousHarvest'
import useSousHarvestV3 from '../../../hooks/useSousHarvestV3'
import useSousHarvestUserLock from '../../../hooks/useSousHarvestUserLock'
import UnstakingFeeCountdownRow from './UnstakingFeeCountdownRow'
import useSousUnstake from '../../../hooks/useSousUnstake'
import useSousUnstakeV3 from '../../../hooks/useSousUnstakeV3'
import useSousUnstakeUserLock from '../../../hooks/useSousUnstakeUserLock'
import { useCurrentBlock } from '../../../store/hooks'
import WithdrawModal from './/WithdrawModal'
import { Clock } from 'react-feather'
import WithdrawUserLockModal from './WithdrawUserLockModal'
import { ScheduleOutlined } from '@ant-design/icons'
// import { Accordion } from 'react-bootstrap';
import { Collapse, Modal } from 'antd'
import 'antd/dist/antd.css'
import DepositModal from './DepositModal'
import { fetchPoolsUserDataAsync } from '../../../store/pools/index'
import useSousStake from '../../../hooks/useSousStake'
import useSousStakeV3 from '../../../hooks/useSousStakeV3'
import Value from '../../../components/Value'
import { getBalanceNumber } from '../../../utils/formatBalance'
import BlockCountdown from './BlockCountdown'
import BlockCountdownDetail from './BlockCountdownDetail'
import WalletProviderModal from '../../../components/WalletProviderModal/WalletProviderModal'
import useModal from '../../../hooks/useModal'
import Button from '../../../components/ButtonV2'
import { useDispatch } from 'react-redux'
import { useWallet } from 'use-wallet'
import useSousApprove from '../../../hooks/useSousApprove'
// import { getPoolBlockHarvest, getPoolBlockUnstake } from '../helpers'
import styled from 'styled-components'
import { ChevronDown, ChevronUp } from 'react-feather'
import { formatNumber } from '../../../utils/formatBalance'
import CulatingImages from './images/calculating.png'
import InfoImages from './images/info.png'
import ApyCalculatorModal from 'components/ApyCalculatorModal'
import { BIG_ZERO } from '../../../utils/bigNumber'
import sousChefV3ABI from '../../../config/abi/sousChefV3.json'
import { getContract } from '../../../utils/contractHelpers'

const PoolCardList = ({ pool, userDataLoaded }) => {
	const [isView, setIsView] = useState(false)
	const { account } = useWallet()
	const dispatch = useDispatch()
	const [requestedApproval, setRequestedApproval] = useState(false)
	const { userData } = pool
	const { onStake } = useSousStake(pool.sousId)
	const { onStakeV3 } = useSousStakeV3(pool.sousId)
	const { onUnstake } = useSousUnstake(pool.sousId)
	const { onUnstakeV3 } = useSousUnstakeV3(pool.sousId)
	const { onUnstakeLock } = useSousUnstakeUserLock(pool.sousId)
	const { onReward } = useSousHarvest(pool.sousId)
	const { onRewardV3 } = useSousHarvestV3(pool.sousId)
	const { onRewardUserLock } = useSousHarvestUserLock(pool.sousId)
	const [pendingTx, setPendingTx] = useState(false)
	const [listSchedule, setListSchedule] = useState([])
	const stakings = userData?.stakedBalance
		? new BigNumber(userData.stakedBalance)
		: BIG_ZERO
	// These will be reassigned later if its Auto CAKE vault
	let stakingTokenBalance = getBalanceNumber(
		stakings,
		pool.stakingToken.decimals,
	)
	let stakingTokenDollarBalance = getBalanceNumber(
		stakings.multipliedBy(pool.stakingTokenPrice),
		pool.stakingToken.decimals,
	)

	const earnings = userData?.pendingReward
		? new BigNumber(userData.pendingReward)
		: BIG_ZERO
	let earningTokenDollarBalance = getBalanceNumber(
		earnings.multipliedBy(pool.earningTokenPrice),
		pool.earningToken.decimals,
	)

	const [onPresentWalletProviderModal] = useModal(
		// @ts-ignore
		<WalletProviderModal />,
		'provider',
	)

	const allowance = userData.allowance
	const { onApprove } = useSousApprove(
		pool.stakingToken.address,
		pool.contractAddress,
	)
	const isApproved = account && allowance && allowance.isGreaterThan(0)

	const handleApprove = useCallback(async () => {
		try {
			setRequestedApproval(true)
			await onApprove()
			dispatch(fetchPoolsUserDataAsync(account))
			setRequestedApproval(false)
		} catch (e) {
			setRequestedApproval(false)
		}
	}, [onApprove, dispatch, account, pool])

	const handleStake = async (amount) => {
		await onStake(amount, pool.stakingToken.decimals)
		dispatch(fetchPoolsUserDataAsync(account))
	}
	const handleStakeV3 = async (amount) => {
		await onStakeV3(amount, pool.stakingToken.decimals)
		dispatch(fetchPoolsUserDataAsync(account))
	}

	const handleUnstake = async (amount) => {
		await onUnstake(amount, pool.stakingToken.decimals)
		dispatch(fetchPoolsUserDataAsync(account))
	}
	const handleUnstakeV3 = async (amount) => {
		await onUnstakeV3(amount, pool.stakingToken.decimals)
		dispatch(fetchPoolsUserDataAsync(account))
	}
	const handleUnstakeUserLock = async (amount) => {
		await onUnstakeLock(amount, pool.stakingToken.decimals)
		dispatch(fetchPoolsUserDataAsync(account))
	}

	const handleReward = useCallback(async () => {
		try {
			setPendingTx(true)
			await onReward()
			dispatch(fetchPoolsUserDataAsync(account))
			setPendingTx(false)
		} catch (e) {
			setPendingTx(false)
			console.error('err handleReward', e)
		}
	}, [account, dispatch, onReward, pool])

	const handleRewardV3 = useCallback(async () => {
		try {
			setPendingTx(true)
			await onRewardV3()
			dispatch(fetchPoolsUserDataAsync(account))
			setPendingTx(false)
		} catch (e) {
			setPendingTx(false)
			console.error('err handleRewardV3', e)
		}
	}, [account, dispatch, onRewardV3, pool])

	const handleRewardUserLock = useCallback(async () => {
		try {
			setPendingTx(true)
			await onRewardUserLock()
			dispatch(fetchPoolsUserDataAsync(account))
			setPendingTx(false)
		} catch (e) {
			setPendingTx(false)
			console.error('err handleRewardUserLock', e)
		}
	}, [account, dispatch, onRewardUserLock, pool])

	const [onPresentStakeInPoolModal] = useModal(
		<DepositModal
			stakingToken={pool.stakingToken}
			max={userData.stakingTokenBalance}
			onConfirm={handleStake}
		/>,
	)
	const [onPresentStakeInPoolModalV3] = useModal(
		<DepositModal
			stakingToken={pool.stakingToken}
			max={userData.stakingTokenBalance}
			onConfirm={handleStakeV3}
		/>,
	)

	const [onPresentWithdrawModal] = useModal(
		<WithdrawModal
			fees={pool.fees}
			lastStakingBlock={userData.lastStakingBlock}
			blockPeriod={pool.blockPeriod}
			stakingToken={pool.stakingToken}
			max={userData.stakedBalance}
			onConfirm={handleUnstake}
		/>,
	)

	const [onPresentWithdrawUserLockModal] = useModal(
		<WithdrawUserLockModal
			fees={pool.fees}
			lastStakingBlock={userData.lastStakingBlock}
			blockPeriod={pool.blockPeriod}
			stakingToken={pool.stakingToken}
			max={userData.unstakeAmount}
			balance={userData.stakedBalance}
			onConfirm={handleUnstakeUserLock}
		/>,
	)
	const [onPresentWithdrawUserLockModalV3] = useModal(
		<WithdrawUserLockModal
			fees={pool.fees}
			lastStakingBlock={userData.lastStakingBlock}
			blockPeriod={pool.blockPeriod}
			stakingToken={pool.stakingToken}
			max={userData.unstakeAmount}
			balance={userData.stakedBalance}
			onConfirm={handleUnstakeV3}
		/>,
	)
	const [onPresentApyModal] = useModal(
		<ApyCalculatorModal
			tokenPrice={pool.earningTokenPrice}
			apr={pool.apr}
			earningTokenSymbol={pool.earningToken.symbol}
			performanceFee={pool.fees}
		/>,
	)
	const { Panel } = Collapse

	function callback(key) {
		console.log(key)
	}
	const [isCollapseVisible, setIsCollapseVisible] = useState(true)

	const [isModalVisible, setIsModalVisible] = useState(false)
	const showModal = () => {
		setIsModalVisible(true)
	}
	const handleOk = () => {
		setIsModalVisible(false)
	}
	const handleCancel = () => {
		setIsModalVisible(false)
	}
	const contractAddressPool = getContract(sousChefV3ABI, pool.contractAddress)
	useEffect(() => {
		if (account && contractAddressPool) {
			contractAddressPool.methods
				.getUserStakedCount(account)
				.call()
				.then(async (resStakedCount) => {
					if (resStakedCount !== '0') {
						let arrData = []
						for (let i = 0; i < parseInt(resStakedCount); i++) {
							arrData.push(
								contractAddressPool.methods.stakeDetails(account, i).call(),
							)
						}
						let listData = []
						// get result
						await Promise.all([arrData]).then((resAll) => {
							for (let i = 0; i < parseInt(resStakedCount); i++) {
								resAll[0][i].then((data) => {
									listData.push(data)
								})
							}
						})
						setTimeout(() => {
							setListSchedule(listData)
						}, 1000)
					}
				})
		}
	}, [account])
	const contentModalClick = (
		<>
			<table className="table-modal">
				<tr>
					<th>Amount</th>
					<th>Stake block</th>
					<th>Remaining unlock</th>
				</tr>
				{listSchedule
					.filter((x) => x.amount > 0)
					.map((item, i) => {
						return (
							<tr key={`schedule_${i}`}>
								<td>{item.amount / 1e18}</td>
								<td>{item.startStakeBlock}</td>
								<td>
									{item.endStakeBlock}{' '}
									<a
										href={`https://bscscan.com/block/countdown/${item.endStakeBlock}`}
										target="_blank"
										rel="noreferrer"
										className="text-white"
									>
										<Clock className="ml-1" size={16} />
									</a>
								</td>
							</tr>
						)
					})}
			</table>
		</>
	)
	const contentHeader = (
		<div className="box-header-list">
			<div className="item-tow-part d-flex">
				<div className="box-logo">
					<img
						src={`/tokens/${pool.earningToken.symbol.toLowerCase()}.png?v=5`}
						alt=""
						className="logo-big"
					/>
					<img
						src={`/tokens/${pool.stakingToken.symbol.toLowerCase()}.png?v=5`}
						alt=""
						className="logo-small"
					/>
				</div>
			</div>
			<div className="item-tow-part d-flex">
				<div className="box-text-logo">
					<p className="text-big">EARN {pool.earningToken.symbol}</p>
					<p className="text-small">Stake {pool.stakingToken.symbol}</p>

					{pool.lockup > 0 && (
						<p className="text-small">Lock-up {pool.lockup} days</p>
					)}
				</div>
			</div>
			<div className="item-tow-part dis-none-max500">
				<div className="box-earned">
					<p className="text-earned">{pool.earningToken.symbol} Earned</p>
					<p className="total-coin">
						<Value
							size={18}
							value={
								account
									? getBalanceNumber(
											userData.pendingReward,
											pool.earningToken.decimals,
									  )
									: 0
							}
						/>
					</p>
				</div>
			</div>
			<div className="item-tow-part d-flex ">
				<div className="box-apr">
					<p className="text-apr">APR</p>
					<p className="number-apr">
						{pool.apr ? <>{formatNumber(pool.apr)}%</> : '0%'}
					</p>
				</div>
			</div>
			<div className="item-tow-part dis-none dis-none-max990">
				<div className="box-total-staked">
					<p className="text-total-staked">Total staked</p>
					<p className="number-total-staked d-flex">
						<Value
							size={16}
							unit="$"
							value={pool.stakedTvl ? +pool.stakedTvl : 0}
						/>
					</p>
				</div>
			</div>
			<div className="item-tow-part dis-none dis-none-max990">
				<div className="box-ends-in">
					<p className="number-ends-in">
						<BlockCountdownDetail pool={pool} />
					</p>
				</div>
			</div>
			<div className="item-tow-part dis-none">
				<Button
					link
					click={() => setIsView(!isView)}
					className="height-80"
					text={isView ? 'Hide' : 'Details'}
					right={
						isView ? (
							<ChevronUp color="#0DBA88" size={16} />
						) : (
							<ChevronDown color="#0DBA88" size={16} />
						)
					}
				/>
			</div>
		</div>
	)
	const contentBody = (
		<div className="box-content-body">
			<div className="item-three-part">
				<p className="line-total-staked dis-mobile">
					<span className="text-left">Total staked:</span>
					<span className="text-right">
						<Value
							size={16}
							unit="$"
							value={pool.stakedTvl ? +pool.stakedTvl : 0}
						/>
					</span>
				</p>
				<p className="text-view-info dis-mobile">
					{pool.isFinished === true ? (
						<UnstakingFeeCountdownRow
							fees={0}
							lastStakingBlock={userData.lastStakingBlock}
							blockPeriod={pool.blockPeriod}
						/>
					) : (
						<UnstakingFeeCountdownRow
							fees={pool.fees}
							lastStakingBlock={userData.lastStakingBlock}
							blockPeriod={pool.blockPeriod}
						/>
					)}
				</p>
				<p className="text-ends-in">
					<BlockCountdown pool={pool} />
				</p>

				<p className="text-view-info hide-mobile">
					{pool.isFinished === true ? (
						<UnstakingFeeCountdownRow
							fees={0}
							lastStakingBlock={userData.lastStakingBlock}
							blockPeriod={pool.blockPeriod}
						/>
					) : (
						<UnstakingFeeCountdownRow
							fees={pool.fees}
							lastStakingBlock={userData.lastStakingBlock}
							blockPeriod={pool.blockPeriod}
						/>
					)}
				</p>
				<p className="text-view-info">
					<a href={`${pool.stakingToken.projectLink}`}>
						View project <img src={InfoImages} alt="" />
					</a>
				</p>
				<p className="text-view-info">
					<a
						href={`https://bscscan.com/address/${pool.contractAddress.toLowerCase()}`}
						target="_blank"
						rel="noreferrer"
					>
						View contract pool
						<img src={InfoImages} alt="" />
					</a>
				</p>
			</div>
			<div className="item-four-part">
				<div className="box-harvest">
					<div className="harvest-left">
						<p className="text-harvest">{pool.earningToken.symbol} Earned</p>
						<p className="total-coin">
							<span className="row-flex">
								<Value
									size={18}
									value={
										account
											? getBalanceNumber(
													userData.pendingReward,
													pool.earningToken.decimals,
											  )
											: 0
									}
								/>
								<span className="mgl-5 card-details">
									<span className="mgl-5 token-earned">
										{pool.earningToken.symbol}
									</span>
								</span>
							</span>
						</p>
						<p className="total-usd">
							<Value size={18} unit={'$'} value={earningTokenDollarBalance} />
						</p>
					</div>
					<div className="harvest-right">
						{pool.userLock ? (
							<>
								{pool.versionSC == 3 ? (
									<Button
										ghost
										disabled={
											!account ||
											pendingTx ||
											userData.pendingReward.eq(new BigNumber(0)) ||
											!isApproved ||
											pool.disHarvest === true
										}
										loading={pendingTx}
										text="Harvest"
										click={handleRewardV3}
									/>
								) : (
									<Button
										ghost
										disabled={
											!account ||
											pendingTx ||
											userData.pendingReward.eq(new BigNumber(0)) ||
											!isApproved ||
											pool.disHarvest === true
										}
										loading={pendingTx}
										text="Harvest"
										click={handleRewardUserLock}
									/>
								)}
							</>
						) : (
							<Button
								ghost
								disabled={
									!account ||
									pendingTx ||
									userData.pendingReward.eq(new BigNumber(0)) ||
									!isApproved ||
									pool.disHarvest === true
								}
								loading={pendingTx}
								text="Harvest"
								click={handleReward}
							/>
						)}
					</div>
				</div>
			</div>
			<div className="item-four-part">
				<div className="box-start-staking">
					<p className="text-start-staking">Start staking</p>
					<span className="schedule-detail-pool">
						<Value
							size={20}
							value={
								account
									? getBalanceNumber(
											userData.stakedBalance,
											pool.stakingToken.decimals,
									  )
									: 0
							}
						/>
					</span>

					<p className="total-usd">
						<Value size={15} unit={'$'} value={stakingTokenDollarBalance} />
					</p>
					<div className="d-flex mar-t-5">
						{pool.userLock ? (
							<>
								{pool.versionSC == 3 ? (
									<Button
										className="mr-2"
										ghost
										disabled={
											!account ||
											userData.stakedBalance.eq(new BigNumber(0)) ||
											!isApproved ||
											pool.disUnstake === true ||
											// hasPoolStarted ||
											userData.unstakeAmount <= 0
										}
										text="Unstake"
										click={onPresentWithdrawUserLockModalV3}
									/>
								) : (
									<Button
										className="mr-2"
										ghost
										disabled={
											!account ||
											userData.stakedBalance.eq(new BigNumber(0)) ||
											!isApproved ||
											pool.disUnstake === true ||
											// hasPoolStarted ||
											userData.unstakeAmount <= 0
										}
										text="Unstake"
										click={onPresentWithdrawUserLockModal}
									/>
								)}
							</>
						) : (
							<Button
								className="mr-2"
								ghost
								disabled={
									!account ||
									userData.stakedBalance.eq(new BigNumber(0)) ||
									!isApproved ||
									pool.disUnstake === true
									// ||
									// hasPoolStarted
								}
								text="Unstake"
								click={onPresentWithdrawModal}
							/>
						)}
						{pool.versionSC == 3 ? (
							<Button
								disabled={
									!account ||
									!isApproved ||
									pool.isFinished === true ||
									pool.disStake === true
								}
								text="+"
								click={onPresentStakeInPoolModalV3}
								primary
							/>
						) : (
							<Button
								disabled={
									!account ||
									!isApproved ||
									pool.isFinished === true ||
									pool.disStake === true
								}
								text="+"
								click={onPresentStakeInPoolModal}
								primary
							/>
						)}
					</div>

					{account ? (
						<>
							{userDataLoaded ? (
								<>
									{!isApproved && (
										<Button
											loading={requestedApproval}
											disabled={requestedApproval}
											className="w-full mar-t-10"
											text="Approve Contract"
											click={handleApprove}
											primary
										/>
									)}
								</>
							) : (
								<Button className="w-full Loading" text="Loading..." />
							)}
						</>
					) : (
						<Button
							className="w-full mar-t-10"
							text="Connect Wallet"
							click={onPresentWalletProviderModal}
							primary
						/>
					)}
				</div>
			</div>
		</div>
	)
	return (
		<>
			<div className="box-list-item ">
				<Collapse
					visible={isCollapseVisible}
					// defaultActiveKey={['1']}
					onChange={callback}
				>
					<Panel header={contentHeader} key="1">
						{contentBody}
					</Panel>
				</Collapse>
			</div>
		</>
	)
}
export default PoolCardList
